import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./index.module.scss";

import { useHistory } from "react-router-dom";
import NoDataFound from "components/common-component/noDataFound/NoDataFound";

export default function ResourceList(props) {
  const { columns, tableData } = props;
  const history = useHistory();

  return (
    <Box>
      {props.Isdataexist ? (
        <Box className={styles.table_box_container}>
          <TableContainer sx={props.addStyle ? {maxHeight: "65vh" }:{maxHeight : "70vh"}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      className={styles.table_head_cell}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ maxHeight: "60vh", overflow: "scroll" }}>
                {tableData.map((row, idx) => {
                  return (
                    <TableRow
                      className={styles.table_row}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={idx}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        const id = row["companyId"];

                        return (
                          <TableCell
                            onClick={() =>
                              props.updateRoute &&
                              history.push(
                                `${props.updateRoute}/${id?.props?.children}`
                              )
                            }
                            className={styles.table_cell}
                            key={column.id}
                            align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
       <NoDataFound errorMsg="NoDataFound"/>
      )}
    </Box>
  );
}
