// Export all the private routes

import Dashboard from "pages/private/dashboard";
import ResourceList from "pages/private/allocations";
import updateResourceAllocation from "pages/private/allocations/update-resource-allocation";
import Resources from "pages/private/resources";
import Projects from "pages/private/projects";
import AdminProfile from "pages/private/profile";
import AdminProfileEdit from "pages/private/profile/editDetail";
import AdminPasswordUpdate from "pages/private/update-password";
export const PrivateRoutes = [
  { path: "/dashboard", exact: true, component: Dashboard },
  { path: "/allocation", exact: true, component: ResourceList },
  { path: "/resources", exact: true, component: Resources },
  { path: "/projects", exact: true, component: Projects },
  { path: "/allocation/:id", exact: true, component: updateResourceAllocation },
  {
    path: "/profile",
    exact: true,
    component: AdminProfile
  },
  {
    path: "/profile/:uuid",
    exact: true,
    component: AdminProfileEdit
  },
  {
    path: "/update-password",
    exact: true,
    component: AdminPasswordUpdate
  },
];
