import { createSlice } from "@reduxjs/toolkit";

const app = createSlice({
  name: "user",
  initialState: {
    userDetails: {},
  },
  reducers: {
    setUserData: (state, action) => {
      state.userDetails = action.payload;
    },
    updateUserDetails: (state, action) => {
      state.userDetails = {
        ...state.userDetails,
        ...action.payload
      };
    }
  }
});

export default app;
export const appActions = app.actions;
