import React
//  { useEffect, useState }
  from "react";
import { Box } from "@mui/material";
import styles from "./Profile.module.scss";
import Heading from "components/common-component/Heading";
import ProfileView from "components/profile-view/ProfileView";

function AdminProfile() {
  return (
    <>
      <Box className={styles.outer_container}>
        <Heading mainHeading="Personal Details" />
        <Box>
            <ProfileView />
        </Box>
      </Box>
    </>
  );
}

export default AdminProfile;
