import React, { useState, useEffect } from "react";
import { Box, Divider, Typography, Button } from "@mui/material";
import styles from "./update-resource-allocation.module.scss";
import { useSelector } from "react-redux";
import Heading from "components/common-component/Heading";
import { useHistory, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import {
  removeAllocateResourceAction,
  updateAllocateResourceAction,
  getResourcesAllocationDataAction,
  removeAllResourceAction
} from "redux/resource-and-project/actions";
import { capitaliseNameInitials } from "components/utilityFunction/UtilityFunction";
import { ProjectsForm } from "components/resourceAllocation/ProjectsForm";
import Breadcrumb from "components/common-component/Breadcrumbs";
import moment from "moment";
import { DeleteResourceModal } from "components/modal/DeleteResourceModal";

function updateResourceAllocation() {
  let pathRoute = [
    { name: "Allocations", path: `/allocation` },
    { name: "Update/Remove Allocation" }
];
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [selectedAllocation, setSelectedAllocation] = useState({});
  const [deleteResourceModalConfig, setDeleteResourceModalConfig] = useState({
    open: false,
    resourceData: null
  });

  const [projectsData, setProjectsData] = useState([
    {
      key: "projectTech",
      label: "Project Technologies"
    },
   
    {
      key: "billable",
      label: "Billable"
    },
    {
      key: "billablePercent",
      label: "Billable Percentage"
    },
     {
      key: "allocatedPercent",
      label: "Allocated Percentage"
    },
    {
      key: "availabilityForDeploy",
      label: "Project Availability for Deployment"
    },
    {
      key: "startedWorkingDate",
      label: "Started Working Date"
    },
    {
      key: "endWorkingDate",
      label: "End Working Date"
    }
  ]);
  const [editableMode, setEditableMode] = useState(false);
  const allResourcesAndProjectsData = useSelector(
    (state) => state.resourceAndProject.projectResourceData.resourceAllocation
  );
  const resourceData = [
    {
      label: "Department",
      value: capitaliseNameInitials(selectedAllocation?.Departement?.name)
    },
    {
      label: "Company ID",
      value: selectedAllocation?.companyId
    },
    {
      label: "Resource Name",
      value: capitaliseNameInitials(selectedAllocation?.resourceName)
    },
    {
      label: "Designation",
      value: capitaliseNameInitials(selectedAllocation?.Designation?.name)
    },
    {
      label: "Resource Expertise",
      value: selectedAllocation?.Expertise?.map((item, index) => (
        <Typography variant="bold"  className={styles.content_name} key={index}>
          {capitaliseNameInitials(item.name)}
        </Typography>
      ))
    },
    {
      label: "Experience Level",
      value: selectedAllocation?.experiencelevel
    }
  ];
  useEffect(() => {
    if (allResourcesAndProjectsData.length > 0) {
      const resourceAllocation = allResourcesAndProjectsData.find(
        (item) => item?.companyId === params.id
      );
      setSelectedAllocation(resourceAllocation);

      setProjectsData(() => {
        return resourceAllocation?.ResourceProjectDetails?.map((project) => {
          return {
            data: projectsData.map((item) => {
              if (item.key === 'startedWorkingDate' || item.key === 'endWorkingDate') {
                return ({
                  key: item?.key,
                  label: item?.label,
                  value: moment(project[item.key]).format('DD-MM-YYYY')
                })
              }
              else {
                return ({
                  key: item?.key,
                  label: item?.label,
                  value: project[item.key]
                })
              }
            }),
            project: project
          };
        });
      });
    }
  }, [allResourcesAndProjectsData]);
  async function handleRemoveAllocation(id) {
    dispatch(removeAllocateResourceAction(id));
  }

  async function handlesubmit(data, id) {
    dispatch(updateAllocateResourceAction({ data, userId: id }));
    dispatch(getResourcesAllocationDataAction());
  }

  const onClickAllocateResource = () => {
    history.push(`/allocation?add=true`);
  };

  const handleDeleteClick = () => {
    setDeleteResourceModalConfig({
      open: true,
      resourceData: ""
    });
  };

  const handleDeleteResourceClose = () => {
    setDeleteResourceModalConfig({
      open: false,
      resourceData: null
    });
  };

  const handleDeleteResource = () => {
    dispatch(removeAllResourceAction(selectedAllocation?.uuid));
    setDeleteResourceModalConfig({
      open: false,
      resourceData: null
    });
    history.push(`/allocation`);
  };

  return (
    <Box sx={{marginLeft:"2rem"}}>
      <Breadcrumb pathRoute={pathRoute}></Breadcrumb>
      <Box className={styles.box_container}>
        <Box className={styles.banner_container}>
          <Heading mainHeading="Update/Remove Allocation" />
          <Box className={styles.link_box}>
            {selectedAllocation?.ResourceProjectDetails?.length === 0 ? (
              <Button
                variant="contained"
                size="large"
                onClick={onClickAllocateResource}>
                Allocate Resource
              </Button>
            ) : (
              <>
                <Button variant="contained" className={styles.first_btn} onClick={() => setEditableMode(!editableMode)}>
                  {editableMode ? "View Projects" : "Edit Projects"}
                </Button>
                <Button variant="outlined" className={styles.second_btn} onClick={handleDeleteClick}>Remove All Allocations</Button>
              </>
            )}
          </Box>
        </Box>
        <Divider light />
        <Box className={styles.project_detail_heading}>Resource Data</Box>
        <Box className={styles.resource_detail_container}>
          {resourceData.map((item, index) => (
            <Box key={index} className={styles.resource_detail}>
              <Typography className={styles.title_name}>{item.label}:</Typography>
              <span className={styles.value_name}>{item.value}</span>
            </Box>
          ))}
        </Box>
        <Divider light />
        {editableMode ? (
          <ProjectsForm
            projects={selectedAllocation}
            onSubmit={handlesubmit}
            handleRemoveAllocation={handleRemoveAllocation}
          />
        ) : (
          <>
            <Box className={styles.project_detail_heading}>Projects Data</Box>
            {projectsData.map((project, index) => (
              <>
                <Box display="flex" paddingLeft="30px" >
                  <Box fontWeight="bold" className={styles.project_box}>Project {index + 1}:</Box>&nbsp;
                   <span className={styles.project_name}>{capitaliseNameInitials(project?.project?.Project?.name)}</span>
                </Box>
                <Box key={index} className={styles.resource_detail_container}>
                  {project?.data?.length > 0 &&
                    project?.data?.map((item) => (
                      <Box key={index} className={styles.resource_detail}>
                        <Typography className={styles.title_name}>{item.label}:</Typography>
                        <span className={styles.value_name}>
                          {typeof item?.value === "object"
                            ? item?.value?.map((item, index) => (
                                <Typography  variant="bold" className={styles.content_name} key={index}>
                                  {capitaliseNameInitials(item)}
                                </Typography>
                              ))
                            : typeof item?.value === "boolean"
                            ? item?.value
                              ? "Yes"
                              : "No"
                            : item?.value}
                        </span>
                      </Box>
                    ))}
                </Box>
              </>
            ))}
          </>
        )}
      </Box>
      <DeleteResourceModal
                content={`Are you sure you want to delete All allocation ?`}
                open={deleteResourceModalConfig.open}
                resource={deleteResourceModalConfig.resourceData}
                onClose={handleDeleteResourceClose}
                onDelete={handleDeleteResource}
              />
    </Box>
  );
}

export default updateResourceAllocation;
