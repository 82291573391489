import React, { useEffect, useMemo, useState } from "react";
import styles from "./resources.module.scss";
import Heading from "components/common-component/Heading";
import { Box, Chip, IconButton } from "@mui/material";
import ResourceListTable from "components/table";
import CreateResource from "components/modal/createResource";
import {
  getAllResourceAction,
  getAllDepartmentsAction,
  getAllDesignationAction,
  getAllExpertiseAction,
} from "redux/resource-and-project/actions";
import { useDispatch, useSelector } from "react-redux";
import { capitaliseNameInitials } from "components/utilityFunction/UtilityFunction";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateResourceModal from "components/modal/UpdateResourceModal";
import { DeleteResourceModal } from "components/modal/DeleteResourceModal";
import { SearchBox } from "components/common-component";
import { ResourceService } from "network/resourceService";
import { useToast } from "hooks";
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import TableLoader from "components/Loader/TableLoader";

function createData(
  companyId,
  resourceName,
  Department,
  experiencelevel,
  Designation,
  expertise,
  actions
) {
  return {
    companyId,
    resourceName,
    Department,
    experiencelevel,
    Designation,
    expertise,
    actions
  };
}

function Resources() {

  const [resourcesSortIcon, setResourcesSortIcon] = useState(true);
  const [expSortIcon, setExpSortIcon] = useState(true);

  const handleResourceSorting = () => {
    setResourcesSortIcon(!resourcesSortIcon);
    handleSorting("resourceName", resourcesSortIcon ? 0 : 1);
    setExpSortIcon(true);
  }

  const handleExpSorting = () => {
    setExpSortIcon(!expSortIcon);
    handleSorting("experiencelevel", expSortIcon ? 0 : 1);
    setResourcesSortIcon(true);
  }

  const handleSorting = (key, value) => {
    let param = {
      sortKey: key,
      sortValue: value
    }
    dispatch(getAllResourceAction(param));
  }

  const columns = [
    {
      id: "companyId",
      label: "Company ID",
      minWidth: 105
    },
    {
      id: "resourceName",
      label: <Box className={styles.tabel_header}>
        <IconButton onClick={handleResourceSorting} >
          {resourcesSortIcon ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
        </IconButton>
        <Box>
          Resource Name
        </Box>
      </Box>,
      minWidth: 170
    },
    {
      id: "Department",
      label: "Department",
      minWidth: 105
    },
    {
      id: "Designation",
      label: "Designation",
      minWidth: 105
    },
    {
      id: "expertise",
      label: "Expertise",
      minWidth: 105
    },
    {
      id: "experiencelevel",
      label: <Box className={styles.tabel_header}>
        <IconButton onClick={handleExpSorting} >
          {expSortIcon ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
        </IconButton>
        <Box>
          Experience Level (years)
        </Box>
      </Box>,
      minWidth: 105
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 105
    }
  ];

  const { showError, showSuccess } = useToast();
  const [updateResourceModalConfig, setUpdateResourceModalConfig] = useState({
    open: false,
    resourceData: null
  });
  const [deleteResourceModalConfig, setDeleteResourceModalConfig] = useState({
    open: false,
    resourceData: null
  });
  const [allResourceListCount, setAllResourceListCount] = useState(0);
  const [Isdataexist, setDataExist] = useState(true);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const dispatch = useDispatch();
  const allResourcesData = useSelector(
    (state) => state.resourceAndProject.resourceData
  );

  const checkLoader = useSelector(
    (state) => state.app.loading
  );

  const allResourcesList = useMemo(() => {

    if (!allResourcesData || allResourcesData?.length === 0) {
      return [];
    }
    return allResourcesData?.map((list, idx) => {
      let experience =
        list?.experiencelevel && parseFloat(list?.experiencelevel).toFixed(1);
      if (list?.experiencelevel && list?.experiencelevel?.includes(".0")) {
        experience = parseInt(list?.experiencelevel);
      }
      return createData(
        <Box key={list.resourceUserId} className={styles.ticketId_name}>
          {list?.companyId}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {list?.resourceName}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {capitaliseNameInitials(list?.Departement?.name)}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {experience ?? "No Experience"}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {capitaliseNameInitials(list?.Designation?.name)}
        </Box>,
        <Box
          key={list.resourceUserId}
          className={styles.ticketId_text}
          display="flex">
          {list?.Expertise?.map((skill, idx) => (
            <Chip key={idx} label={skill.name} size="small" sx={{ mr: 0.5 }} />
          ))}
        </Box>,
        <Box
          key={list.resourceUserId}
          className={styles.ticketId_text}
          display="flex">
          <IconButton
            aria-label="edit"
            size="small"
            sx={{ mr: 0.5 }}
            onClick={() => handleEditClick(idx)}>
            <EditIcon />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="delete"
            size="small"
            sx={{ mr: 0.5 }}
            onClick={() => handleDeleteClick(idx)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      );
    });
  }, [allResourcesData]);

  useEffect(() => {
    dispatch(getAllDepartmentsAction());
    dispatch(getAllDesignationAction());
    dispatch(getAllExpertiseAction());
  }, []);

  useEffect(() => {
    if (allResourcesData.length > 0) {
      setAllResourceListCount(allResourcesData.length);
      setDataExist(true);
    }
  }, [allResourcesData]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const handleEditClick = (idx) => {
    setUpdateResourceModalConfig({
      open: true,
      resourceData: allResourcesData[idx]
    });
  };

  const handleDeleteClick = (idx) => {
    setDeleteResourceModalConfig({
      open: true,
      resourceData: allResourcesData[idx]
    });
  };

  const handleUpdateResourceClose = () => {
    setUpdateResourceModalConfig({
      open: false,
      resourceData: null
    });
  };

  const handleDeleteResourceClose = () => {
    setDeleteResourceModalConfig({
      open: false,
      resourceData: null
    });
  };

  const handleUpdateResource = React.useCallback(
    async (resourceData) => {
      const response = await ResourceService.updateResource(resourceData, [updateResourceModalConfig.resourceData.uuid]);

      if (response?.code === 200) {
        showSuccess({ message: response?.message|| "Resource updated successfully" });
        dispatch(getAllResourceAction());
        setUpdateResourceModalConfig({
          open: false,
          resourceData: null
        });
      } else {
        showError({
          message: response?.data?.message || "Something went wrong"
        });
      }
    },
    [updateResourceModalConfig.resourceData]
  );

  const handleSearch = (search) => {
    let param = {
      search: search
    }
    dispatch(getAllResourceAction(param));
  };


  const handleDeleteResource = React.useCallback(async () => {
    const response = await ResourceService.deleteResource([deleteResourceModalConfig.resourceData.uuid]);
    if (response?.code === 200) {
      showSuccess({ message: response?.message || "Resource deleted successfully" });
      dispatch(getAllResourceAction());
      setDeleteResourceModalConfig({
        open: false,
        resourceData: null
      });
    } else {
      showError({ message: response?.data?.message || "Something went wrong" });
    }
  }, [deleteResourceModalConfig.resourceData]);

  return (
    <>
      <Box className={styles.outer_container}>
        <Heading mainHeading="Resource List" />
        <Box className={styles.employee_list_wrapper}>
          <Box className={styles.box_container}>
            <Box className={styles.employee_list_search_container}>
              <Box className={styles.sub_container}>
                <Box className={styles.search_box}>
                  <Box className={styles.employees_text}>
                    {allResourceListCount} Resources
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box marginRight={2}>
                      <SearchBox placeholder="Search" onChange={handleSearch} />
                    </Box>
                    <Box className={styles.issue_button_container}>
                      <CreateResource buttonTitle="Create Resource" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* For mobile responsive */}
            <Box
              className={styles.mobile_search_box}
              sx={{ display: { xs: "block", sm: "none" } }}></Box>
            <Box
              className={styles.table_container}
              sx={{
                maxWidth: `${windowSize.innerWidth - 35}px`
              }}>
              {checkLoader ? <TableLoader /> :
                <ResourceListTable
                  route="resources"
                  columns={columns}
                  tableData={allResourcesList}
                  createData={createData}
                  Isdataexist={Isdataexist}
                />
              }
              <UpdateResourceModal
                open={updateResourceModalConfig.open}
                resource={updateResourceModalConfig.resourceData}
                onClose={handleUpdateResourceClose}
                onUpdate={handleUpdateResource}
              />
              <DeleteResourceModal
                content={`Are you sure you want to delete ${deleteResourceModalConfig.resourceData?.resourceName ?? ""
                  }?`}
                open={deleteResourceModalConfig.open}
                resource={deleteResourceModalConfig.resourceData}
                onClose={handleDeleteResourceClose}
                onDelete={handleDeleteResource}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Resources;
