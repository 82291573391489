import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { OutlinedInput } from "@mui/material";
import Button from "@mui/material/Button";
import { Field, Form, Formik } from "formik";
import { object, string } from "yup";
import styles from "./forgotpassword.module.scss";
import { useHistory } from "react-router-dom";
import { forgotPasswordAction, updateCountAction } from "redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";

function ForgotPassWord() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorHandle, setErrorHandle] = useState(false);
  const forgotPasswordData = useSelector((state) => state.forgotPassword.forgotPassword);

  useEffect(() => {
    if(forgotPasswordData.count === 4){
      dispatch(updateCountAction(0));
    }
    else if (forgotPasswordData.email !== "" && forgotPasswordData.count !== 0) {
      history.push("/email-sent");
    }
    
  }, [forgotPasswordData]);

  const handlesubmit = (data) => {
    dispatch(
      forgotPasswordAction({
        ...data
      })
    );
    setErrorHandle(false);
  };

  function handleLogin() {
    history.push("/login");
  }
  return (
    <Box>
      <Box className={styles.outer_container}>
        <Typography variant="h2" component="h2" className={styles.main_heading}>
          Forgot Password
        </Typography>
        <Typography variant="h5" component="h5" className={styles.sub_heading}>
          Please enter your registered email id. A link will be sent to your
          mail to reset your password.
        </Typography>
      </Box>
      <Box className={styles.form_container}>
        <Typography variant="h6" component="h6" className={styles.form_heading}>
          Email ID
        </Typography>

        <Formik
          initialValues={{ email: "" }}
          onSubmit={handlesubmit}
          validationSchema={object({
            email: string()
              .required("Email ID is Required")
              .trim()
              .email("Invalid Email id")
          })}>
          {({ errors, touched, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Field
                name="email"
                type="text"
                id="email_id"
                as={OutlinedInput}
                className={styles.input_field}
                placeholder="Enter your Email"
                fullWidth
                error={Boolean(errors.email) && Boolean(touched.email)}
              />
              <Box className={styles.text_error} data-testid="emailError">
                {errors.email && touched.email ? (
                  <Typography
                    variant="h6"
                    component="h6"
                    className={styles.error_message}>
                    {errors.email}
                  </Typography>
                ) : errorHandle === true ? (
                  <Typography
                    variant="h6"
                    component="h6"
                    className={styles.error_message}>
                    Email ID does not exist
                  </Typography>
                ) : null}
              </Box>
              <Box className={styles.button_box}>
                <Button
                  className={styles.submit_button}
                  id="forgot_password"
                  type="submit"
                  variant="contained"
                  disableRipple>
                  Submit
                </Button>
                <Button
                  onClick={handleLogin}
                  className={styles.login_button}
                  variant="contained">
                  Login
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default ForgotPassWord;
