import { createAction } from "@reduxjs/toolkit";

// Get APIs
export const getResourcesAllocationDataAction = createAction(
  "getResourcesAllocationDataAction"
);
export const getAllResourceAction = createAction("getAllResourceAction");
export const getAllProjectsAction = createAction("getAllProjectsAction");
export const getAllDepartmentsAction = createAction("getAllDepartmentsAction");
export const getAllDesignationAction = createAction("getAllDesignationAction");
export const getAllExpertiseAction = createAction("getAllExpertiseAction");
export const getAllocationByFiltersAction = createAction(
  "getAllocationByFiltersAction"
);
export const getCSVFileAction = createAction("getCSVFileAction");

// Add APIs
export const addNewResourceAction = createAction("addNewResourceAction");
export const addNewDepartmentAction = createAction("addNewDepartmentAction");
export const addNewDesignationAction = createAction("addNewDesignationAction");
export const addNewProjectAction = createAction("addNewProjectAction");
export const addNewSKillAction = createAction("addNewSKillAction");
export const AllocateResourceAction = createAction("AllocateResourceAction");
export const UploadCSVFileAction = createAction("UploadCSVFileAction");

// Update APIs
export const updateAllocateResourceAction = createAction(
  "updateAllocateResourceAction"
);
export const updateResourceAction = createAction("updateResourceAction");

// Remove APIs
export const removeAllocateResourceAction = createAction(
  "removeAllocateResourceAction"
);
export const removeAllResourceAction = createAction(
  "removeAllResourceAction"
);
export const removeResourceAction = createAction("removeResourceAction");

