import React, { useState } from "react";
import { Box, OutlinedInput, Button, Typography } from "@mui/material";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import styles from "./UpdatePassword.module.scss";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { AuthService } from "network/authService";
import { useHistory } from "react-router-dom";
import { useToast } from "hooks";
function UpdatePassword() {
  const [showOldPassword, setshowOldPassword] = useState(false);
  const [showNewPassword, setshowNewPassword] = useState(false);
  const [showConfPassword, setshowConfPassword] = useState(false);
  const { showSuccess, showError } = useToast();
  const history = useHistory();
  const handleOldPassword = () => {
    setshowOldPassword(!showOldPassword);
  };
  const handleNewPassword = () => {
    setshowNewPassword(!showNewPassword);
  };
  const handleConfPassword = () => {
    setshowConfPassword(!showConfPassword);
  };

  const handleMouseDownOldPassword = (event) => {
    event.preventDefault();
    setshowOldPassword(!showOldPassword);
  };
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
    setshowNewPassword(!showNewPassword);
  };
  const handleMouseDownConfPassword = (event) => {
    event.preventDefault();
    setshowConfPassword(!showConfPassword);
  };

  let initialValues = {
    oldpassword: "",
    newpassword: "",
    repassword: ""
  };
 
  const validationSchema = Yup.object().shape({
    oldpassword: Yup.string().required("Old-password is required").trim(),
    newpassword: Yup.string()
      .required("required")
      .trim()
      .matches(/^(?=.*[A-Z])/, "One Uppercase required")
      .matches(/^(?=.*[!@#$%^&*])/, "One Special Case Character required")
      .matches(/^(?=.*\d)/, "One Number required")
      .min(8, "Password must be at least 8 characters")
      .max(32, "Password must not exceed 32 characters"),
    repassword: Yup.string()
      .required("required")
      .trim()
      .oneOf([Yup.ref("newpassword")], "Password doesn't match")
  });
  async function handlesubmit(data){
    let body = {
      currentPassword: data.oldpassword,
      newPassword: data.newpassword,
      confirmNewPassword: data.repassword
    };
    let changePassword = await AuthService.updatePassword(body);
    console.log("entered password===>", body)
    console.log("password===>", changePassword)
    if(changePassword.code === 200 && changePassword.success === true){
      showSuccess({ message: "Password Created successfully" });
      history.push("/dashboard")
    }
    else {
      showError({ message: changePassword?.message || "Something went wrong" });
    }
  }
  function handleCancel(){
    history.push("/dashboard")
  }
  return (
    <>
      <Box className={styles.box_container}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handlesubmit}
          >
          {(formik) => {
            let checkPasswordValidation = formik.values["newpassword"].match(
              /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d@$!%*#?&]{8,32}$/
            );

            const isFormValid =
              formik.isValid && Object.keys(formik.touched).length > 0;

            return (
              <Form 
              onSubmit={formik.handleSubmit}
              >
                <Box className={styles.edit_box_container}>
                  <Box className={styles.edit_container}>
                    <Box className={styles.text_container}>
                      <Box className={styles.inputbox_inner_container}>
                        <Box>
                          <Box className={styles.heading_container}>
                            Old Password
                          </Box>
                          <Box className={styles.text_field_container}>
                            <Field
                              as={OutlinedInput}
                              className={styles.text_field}
                              type={showOldPassword ? "text" : "password"}
                              placeholder="Enter Old Password"
                              name="oldpassword"
                              spellCheck="false"
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleOldPassword}
                                    onMouseDown={handleMouseDownOldPassword}
                                    edge="end">
                                    {showOldPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className={styles.inputbox_inner_container}>
                        <Box className={styles.heading_container}>
                          New Password
                        </Box>
                        <Box className={styles.text_field_container}>
                          <Field
                            as={OutlinedInput}
                            className={styles.text_field}
                            type={showNewPassword ? "text" : "password"}
                            placeholder="Enter New Password"
                            name="newpassword"
                            spellCheck="false"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleNewPassword}
                                  onMouseDown={handleMouseDownNewPassword}
                                  edge="end">
                                  {showNewPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </Box>
                        <Box className={styles.bullet_container}>
                          <Box className={styles.bullet_point}> </Box>
                          <Box className={styles.error_container}>
                            <Typography
                              className={styles.bullet_point_text_typography}
                              variant="subtitle2"
                              sx={{
                                textDecoration:
                                  checkPasswordValidation &&
                                  checkPasswordValidation.length > 0
                                    ? "line-through"
                                    : "none"
                              }}>
                              Please include atleast 8 or max 32 characters with
                              a mix of special character, uppercase and numbers
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box className={styles.inputbox_inner_container}>
                        <Box className={styles.heading_container}>
                          Confirm Password
                        </Box>
                        <Box className={styles.text_field_container}>
                          <Field
                            as={OutlinedInput}
                            className={styles.text_field}
                            type={showConfPassword ? "text" : "password"}
                            placeholder="Confirm New Password"
                            name="repassword"
                            spellCheck="false"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleConfPassword}
                                  onMouseDown={handleMouseDownConfPassword}
                                  edge="end">
                                  {showConfPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </Box>
                        <Box className={styles.bullet_container}>
                          <Box className={styles.error_container}>
                            {formik.errors.repassword === "required" ? (
                              ""
                            ) : (
                              <Typography
                                variant="h6"
                                component="h6"
                                className={styles.error_message}>
                                {formik.errors.repassword}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={styles.button_container}>
                      <Box className={styles.cancel_btn_box}>
                        <Button
                          onClick={handleCancel}
                          className={styles.cancel_btn}>
                          Cancel
                        </Button>
                      </Box>
                      <Box className={styles.submit_btn_box}>
                        <Button
                          type="submit"
                          className={
                            isFormValid
                              ? styles.save_btn
                              : styles.dissble_submit_button
                          }>
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
}

export default UpdatePassword;
