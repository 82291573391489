// Export all the public routes

import Login from "pages/public/login";
import ForgotPassword from "pages/public/forgot-password";
import ResetPasswordLinkSend from "pages/public/resetlinksent";
import ResetPassWord from "pages/public/reset-password";
import ResetPassWordSuccess from "pages/public/reset-password/success";

export const PublicRoutes = [
  { path: "/login", exact: true, component: Login },
  { path: "/forgot-password", exact: true, component: ForgotPassword },
  { path: "/email-sent", exact: true, component: ResetPasswordLinkSend },
  {
    path: "/auth/reset-password/:authtoken",
    exact: true,
    component: ResetPassWord
  },
  {
    path: "/reset-password/success",
    exact: true,
    component: ResetPassWordSuccess
  }
];
