import React,{useEffect,useState} from "react";
import { Box } from "@mui/material";
import styles from "./DashboardCard.module.scss";
import Groups2Icon from '@mui/icons-material/Groups2';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AddCardIcon from '@mui/icons-material/AddCard';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { DashboardServices } from "network/dashboardService";
import {useHistory} from "react-router-dom"
function VerticalCard() {
  const history = useHistory()
  const [allCount, setAllCount] = useState({
          billable : 0,
          departmentCount : 0,
          designationCount : 0,
          nonBillable :  0,
          resourceCount : 0,
          projectCount : 0,
  })
  useEffect(() => {
    getDashboardCount();
}, []);
  async function getDashboardCount() {
    let dashboardCount = await DashboardServices.addDepartment();
    console.log("count==>", dashboardCount)
    if (dashboardCount.code === 200 && dashboardCount.success === true) {
      let data = dashboardCount?.data
      setAllCount({
        ...allCount,
        billable : data?.billable,
        departmentCount : data?.departmentCount,
        designationCount : data?.designationCount,
        nonBillable :  data?.nonBillable,
        resourceCount : data?.resourceCount,
        projectCount : data?.projectCount
      });
  }
  else {
      setAllCount([]);
  }
  }
  const dashboardArray = [
    {
      cardIcon: <Groups2Icon sx={{color : "#1976d2", height:"2rem", width:"2rem"}}/>,
      title: "Total Resources",
      count: allCount?.resourceCount ? allCount?.resourceCount : 0,
      link:"/resources"
    },
    {
      cardIcon: <CreateNewFolderIcon sx={{color : "#1976d2", height:"2rem", width:"2rem"}}/>,
      title: "Total Projects",
      count: allCount?.projectCount ? allCount?.projectCount : 0,
      link : "/projects"
    },
    {
      cardIcon: <AttachMoneyIcon  sx={{color : "#1976d2", height:"2rem", width:"2rem"}}/>,
      title: "Total Billable",
      count: allCount?.billable ? allCount?.billable : 0,
      link : "/allocation"
    },
    {
      cardIcon: <MoneyOffIcon  sx={{color : "#1976d2", height:"2rem", width:"2rem"}}/>,
      title: "Total Non Billable",
      count: allCount?.nonBillable ? allCount?.nonBillable : 0,
      link : "/allocation"
    },
    {
      cardIcon: <AddCardIcon  sx={{color : "#1976d2", height:"2rem", width:"2rem"}}/>,
      title: "Total Departments",
      count: allCount?.departmentCount ? allCount?.departmentCount : 0,
      link : "/projects"
    },
    {
      cardIcon: <Groups2Icon  sx={{color : "#1976d2",  height:"2rem", width:"2rem"}}/>,
      title: "Total Designations",
      count: allCount?.designationCount ? allCount?.designationCount : 0,
      link : "/projects"
    }
  ];
  return (
    <Box>
      <Box
        className={styles.vertical_card_container}>
        {dashboardArray.map((data, index) => (
          <Box
            key={index}
            className={index==4 || index==5 ? styles.border_box : styles.box_container} onClick={()=>history.push(data.link)}>
            <Box>
              <Box className={styles.icon_name_box}>
                <Box className={styles.img_container}>{data.cardIcon}</Box>
                <Box className={styles.vertical_heading_container}>
                  {data.title}
                </Box>
              </Box>
            </Box>
            <Box className={styles.vertical_counter}>{data.count}</Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
export default VerticalCard;
