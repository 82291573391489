import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllResourceAction } from "redux/resource-and-project/actions";

const useResourceChoices = () => {
  const dispatch = useDispatch();
  const { resourceData: resourceChoices } = useSelector(
    (state) => state.resourceAndProject
  );

  const refetch = () => {
    dispatch(getAllResourceAction());
  };

  React.useEffect(() => {
    refetch();
  }, []);

  return {
    resourceChoices,
    refetch
  };
};

export { useResourceChoices };
