import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { appReducers } from "./rootSlice";
import sagas from "./rootSagas";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import "../interceptor";

const combinedReducers = combineReducers({
  ...appReducers
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ['forgotPassword']
};

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, combinedReducers);

const middleware = [
  ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
  sagaMiddleware
];

const store = configureStore({
  reducer: persistedReducer,
  middleware
});

sagaMiddleware.run(sagas);

export default store;
