import React from "react";
import { Formik, Field, Form } from "formik";
import {
  Button,
  Typography,
  Divider,
  Autocomplete,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment
} from "@mui/material";
import { useSelector } from "react-redux";
import { useSkillChoices } from "hooks";

const convertToFormikFormat = (data) => {
  return {
    uuid: data?.uuid,
    companyId: data?.companyId?.split("NFX")[1],
    resourceName: data?.resourceName,
    experiencelevel: data?.experiencelevel,
    departmentId: data?.departmentId,
    designationId: data?.designationId,
    availabilityForDeploy: data?.availabilityForDeploy,
    expertise: data?.Expertise
  };
};

const UpdateResourceModal = ({ open, onClose, resource, onUpdate }) => {
  if (!resource) {
    return null;
  }

  const { skillChoices } = useSkillChoices();
  const allDepartmentsData = useSelector(
    (state) => state.resourceAndProject.departmentsData
  );
  const allDesignationsData = useSelector(
    (state) => state.resourceAndProject.designationsData
  );

  const formData = React.useMemo(
    () => convertToFormikFormat(resource),
    [resource]
  );
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    onClose && onClose();
  };

  const onSubmitHandler = async (formData) => {
    const data = {
      ...formData,
      companyId: "NFX" + formData.companyId,
      expertise: formData?.expertise?.map((item) => item?.uuid),
      experiencelevel: parseFloat(formData.experiencelevel)
    };
    onUpdate && onUpdate(data);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        <Typography variant="h5">Update Resource</Typography>
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={formData}
        onSubmit={onSubmitHandler}
        enableReinitialize>
        {(formik) => {
          return (
            <Form>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                      Resource Company ID
                    </Typography>
                    <Field
                      as={TextField}
                      placeholder="Company ID"
                      fullWidth
                      valueData={formik.values.departmentId}
                      name="companyId"
                      size="small"
                      multiline={true}
                      spellCheck="false"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">NFX</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                      Resource Name
                    </Typography>
                    <Field
                      as={TextField}
                      placeholder="Resource Name"
                      fullWidth
                      name="resourceName"
                      size="small"
                      multiline={true}
                      spellCheck="false"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                      Experience Level (years)
                    </Typography>
                    <Field
                      as={TextField}
                      placeholder="Experience Level"
                      fullWidth
                      name="experiencelevel"
                      size="small"
                      type="number"
                      multiline={true}
                      spellCheck="false"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                      Department
                    </Typography>
                    <Autocomplete
                      options={allDepartmentsData}
                      getOptionLabel={(option) => option.name}
                      value={allDepartmentsData.find(
                        (department) =>
                          department.uuid === formik.values.departmentId
                      )}
                      onChange={(event, value) =>
                        formik.setFieldValue("departmentId", value?.uuid)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="eg. John Doe"
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                      Designation
                    </Typography>
                    <Autocomplete
                      options={allDesignationsData}
                      getOptionLabel={(option) => option.name}
                      value={allDesignationsData.find(
                        (designation) =>
                          designation.uuid === formik.values.designationId
                      )}
                      onChange={(event, value) =>
                        formik.setFieldValue("designationId", value.uuid)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="eg. Software Engineer"
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                      Expertise
                    </Typography>
                    <Autocomplete
                      multiple
                      isOptionEqualToValue={(option, value) =>
                        option.uuid === value.uuid
                      }
                      disableCloseOnSelect
                      options={skillChoices}
                      onChange={(event, value) =>
                        formik.setFieldValue("expertise", value)
                      }
                      getOptionLabel={(option) => option.name}
                      value={formik.values.expertise}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="eg. Java"
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider light />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="outlined" size="large">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" size="large">
                  Update
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default UpdateResourceModal;
