import React, { useEffect, useState } from "react";
import styles from "./resources.module.scss";
import { Box, IconButton } from "@mui/material";
import ResourceListTable from "components/table";
import { getAllDesignationAction } from "redux/resource-and-project/actions";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "hooks";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteResourceModal } from "components/modal/DeleteResourceModal";
import { UpdateDesignationModal } from "components/modal";
import { DesignationService } from "network/designationService";
import TableLoader from "components/Loader/TableLoader";

const columns = [
  {
    id: "name",
    label: "Name",
    minWidth: 105
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 105
  }
];

function createData(name, actions) {
  return {
    name,
    actions
  };
}

function DesignationTable() {
  const dispatch = useDispatch();
  const { showError, showSuccess } = useToast();
  const [updateDesignationModalConfig, setUpdateDesignationModalConfig] =
    useState({
      open: false,
      designationData: null
    });
  const [deleteDesignationModalConfig, setDeleteDesignationModalConfig] =
    useState({
      open: false,
      designationData: null
    });
  const [allProjectsListCount, setAllProjectsListCount] = useState(0);
  const [Isdataexist, setDataExist] = useState(true);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const allDesignationsData = useSelector(
    (state) => state.resourceAndProject.designationsData
  );

  const checkLoader = useSelector(
    (state) => state.app.loading
  );

  useEffect(() => {
    dispatch(getAllDesignationAction());
  }, []);

  useEffect(() => {
    if (allDesignationsData.length > 0) {
      setDataExist(true);
      setAllProjectsListCount(allDesignationsData.length);
    }
  }, [allDesignationsData]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const handleEditClick = (idx) => {
    setUpdateDesignationModalConfig({
      open: true,
      designationData: allDesignationsData[idx]
    });
  };

  const handleUpdateDesignation = React.useCallback(
    async (designationData) => {
      const response = await DesignationService.updateDesignation(
        designationData,
        [updateDesignationModalConfig.designationData.uuid]
      );
      if (response?.code === 200) {
        showSuccess({ message: response?.message ||  "Designation updated successfully" });
        dispatch(getAllDesignationAction());
        setUpdateDesignationModalConfig({
          open: false,
          designationData: null
        });
      } else {
        showError({
          message: response?.data?.message || "Something went wrong"
        });
      }
    },
    [updateDesignationModalConfig.designationData]
  );

  const handleUpdateDesignationClose = () => {
    setUpdateDesignationModalConfig({
      open: false,
      designationData: null
    });
  };

  const handleDeleteClick = (idx) => {
    setDeleteDesignationModalConfig({
      open: true,
      designationData: allDesignationsData[idx]
    });
  };

  const handleDeleteDesignationClose = () => {
    setDeleteDesignationModalConfig({
      open: false,
      designationData: null
    });
  };

  const handleDeleteDesignation = React.useCallback(async () => {
    const response = await DesignationService.deleteDesignation([deleteDesignationModalConfig.designationData.uuid]);
    if (response?.code === 200) {
      showSuccess({ message: response?.message || "Designation deleted successfully" });
      dispatch(getAllDesignationAction());
      setDeleteDesignationModalConfig({
        open: false,
        designationData: null
      });
    } else {
      showError({ message: response?.data?.message || "Something went wrong" });
    }
  }, [deleteDesignationModalConfig.designationData]);

  const allDesignationsList = React.useMemo(() => {
    if (!allDesignationsData || allDesignationsData?.length === 0) {
      return [];
    }
    return allDesignationsData?.map((list, idx) => {
      return createData(
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {list?.name}
        </Box>,
        <Box key={idx} className={styles.ticketId_text} display="flex">
          <IconButton
            aria-label="edit"
            size="small"
            sx={{ mr: 0.5 }}
            onClick={() => handleEditClick(idx)}>
            <EditIcon />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="delete"
            size="small"
            sx={{ mr: 0.5 }}
            onClick={() => handleDeleteClick(idx)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      );
    });
  }, [allDesignationsData]);

  return (
    <>
      <Box className={styles.box_container}>
        <Box className={styles.employee_list_search_container}>
          <Box className={styles.sub_container}>
            <Box className={styles.search_box}>
              {false && (
                <Box className={styles.employees_text}>
                  {allProjectsListCount} Project
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {/* For mobile responsive */}
        <Box
          className={styles.mobile_search_box}
          sx={{ display: { xs: "block", sm: "none" } }}></Box>
        <Box
          className={styles.table_container}
          sx={{
            maxWidth: `${windowSize.innerWidth - 35}px`
          }}>
          {checkLoader ? <TableLoader /> :
            <ResourceListTable
              route="resources"
              createData={createData}
              columns={columns}
              tableData={allDesignationsList}
              Isdataexist={Isdataexist}
            />
          }
          <UpdateDesignationModal
            open={updateDesignationModalConfig.open}
            designation={updateDesignationModalConfig.designationData}
            onClose={handleUpdateDesignationClose}
            onUpdate={handleUpdateDesignation}
          />
          <DeleteResourceModal
            content={`Are you sure you want to delete ${
              deleteDesignationModalConfig.designationData?.name ?? ""
            }?`}
            title="Delete Designation" 
            open={deleteDesignationModalConfig.open}
            onClose={handleDeleteDesignationClose}
            onDelete={handleDeleteDesignation}
          />
        </Box>
      </Box>
    </>
  );
}

export default DesignationTable;
