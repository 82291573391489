import app from "./app/slice";
import auth from "./auth/slice";
import forgotPassword from "./auth/forgotPasswordSlice";
import resource from "./resource-and-project/slice";
import charts from "./charts/slice";

export const appReducers = {
  app: app.reducer,
  auth: auth.reducer,
  forgotPassword: forgotPassword.reducer,
  resourceAndProject: resource.reducer,
  charts: charts.reducer,
};
