import React, { useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { capitaliseNameInitials } from "components/utilityFunction/UtilityFunction";
import { useSkillChoices } from "hooks";
import styles from "./ProjectForm.module.scss"
const initialProjectValues = [
  {
    resourceUserId: "",
    projectId: "",
    projectTech: [],
    billable: false,
    billablePercent: "",
    allocatedPercent: "",
    startedWorkingDate: "",
    endWorkingDate: ""
  }
];

const convertToFormData = (projects) => {
  if (!projects || !projects.length) {
    return initialProjectValues;
  }
  return projects.map((project) => ({
    resourceUserId: project.ResourceUserUuid,
    name: project.Project?.name,
    allocationId: project.uuid,
    projectId: project.Project?.uuid,
    projectTech: project.projectTech,
    billable: project.billable,
    billablePercent: project.billablePercent,
    allocatedPercent: project.allocatedPercent,
    availabilityForDeploy: project.availabilityForDeploy,
    startedWorkingDate: project.startedWorkingDate,
    endWorkingDate: project.endWorkingDate
  }));
};

const ProjectsForm = ({ projects = [], onSubmit, handleRemoveAllocation }) => {
  const [formData, setFormData] = React.useState(initialProjectValues);
  const { skillChoices } = useSkillChoices();

  useEffect(() => {
    setFormData(convertToFormData(projects.ResourceProjectDetails));
  }, [projects.ResourceProjectDetails]);

  const handleRemoveProject = (index, id) => {
    const newFormData = [...formData];
    newFormData.splice(index, 1);
    setFormData(newFormData);
    handleRemoveAllocation(id);
  };

  const handleChange = (index, field, value) => {
    const newFormData = [...formData];
    newFormData[index][field] = value;
    setFormData(newFormData);
  };

  const handleSubmit = (id) => {
    const updatedData = formData.find((item) => item.allocationId === id);
    onSubmit(updatedData, id);
  };

  return (
    <Box padding="30px">
      {formData.map((project, index) => (
        <Box key={index} marginBottom={10}>
          <Box marginBottom={5}>
            <Box fontWeight="bold" className={styles.project_box}>Project {index + 1}: <span className={styles.project_name}>{capitaliseNameInitials(project?.name)}</span></Box> 
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={skillChoices}
                getOptionLabel={(option) => option.name}
                value={skillChoices.filter((skill) =>
                  project.projectTech.includes(skill.name)
                )}
                onChange={(event, value) => {
                  handleChange(
                    index,
                    "projectTech",
                    value.map((item) => item.name)
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project Tech"
                    placeholder="eg. Java"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={project.billable}
                    onChange={(event) => {
                      handleChange(index, "billable", event.target.checked);
                      !event.target.checked &&
                        handleChange(index, "billablePercent", "");
                    }}
                    name="billable"
                    color="primary"
                  />
                }
                label="Billable?"
              />
            </Grid>
            <Grid item xs={2}>
              {project.billable && (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-billable">
                    Billable Percent
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-billable"
                    placeholder="eg. 50"
                    label="Billable Percent"
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                    aria-describedby="outlined-billable-helper-text"
                    inputProps={{
                      "aria-label": "billable"
                    }}
                    fullWidth
                    value={project.billablePercent}
                    onChange={(event) =>
                      handleChange(index, "billablePercent", event.target.value)
                    }
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-allocated">
                  Allocated Percent
                </InputLabel>
                <OutlinedInput
                  placeholder="eg. 50"
                  id="outlined-adornment-allocated"
                  label="Allocated Percent"
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  aria-describedby="outlined-allocated-helper-text"
                  inputProps={{
                    "aria-label": "allocated"
                  }}
                  fullWidth
                  value={project.allocatedPercent}
                  onChange={(event) =>
                    handleChange(index, "allocatedPercent", event.target.value)
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-allocated">
                Project Availability for Deployment
                </InputLabel>
                <OutlinedInput
                  placeholder="eg. 50"
                  id="outlined-adornment-allocated"
                  label="Project Availability for Deployment"
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  aria-describedby="outlined-allocated-helper-text"
                  inputProps={{
                    "aria-label": "allocated"
                  }}
                  fullWidth
                  value={project.availabilityForDeploy}
                  onChange={(event) =>
                    handleChange(index, "availabilityForDeploy", event.target.value)
                  }
                />
              </FormControl>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={4}>
                <DesktopDatePicker
                  label="Start Working Date"
                  inputFormat="yyyy-MM-dd"
                  value={project.startedWorkingDate}
                  onChange={(date) =>
                    handleChange(index, "startedWorkingDate", date)
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={4}>
                <DesktopDatePicker
                  label="End Working Date"
                  inputFormat="yyyy-MM-dd"
                  value={project.endWorkingDate || null}
                  onChange={(date) =>
                    handleChange(index, "endWorkingDate", date)
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Box sx={{display:"flex", marginTop:"2rem"}}>
              <Box>
                <Button
                  variant="outlined"
                  className={styles.second_btn}
                  color="primary"
                  onClick={() =>
                    handleRemoveProject(index, project.allocationId)
                  }>
                  Remove
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  className={styles.first_btn}
                  color="primary"
                  onClick={() => handleSubmit(project.allocationId)}>
                  Update
                </Button>
              </Box>
            </Box>
        </Box>
      ))}
    </Box>
  );
};

export { ProjectsForm };
