import React, { useState } from "react";
import styles from "./index.module.scss";
import { Formik, Field, Form } from "formik";
import {
  Button,
  Modal,
  Typography,
  Box,
  Divider,
  OutlinedInput
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  addNewDesignationAction,
  getAllDesignationAction
} from "redux/resource-and-project/actions";
import AddIcon from "@mui/icons-material/Add";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "90vh",
  // minHeight: "40vh",
  transform: "translate(-50%, -50%)",
  // minWidth: "42%",
  maxWidth: "60%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  backgroundColor: "#fff",
  p: 4,
  padding: "1.5rem",
  justifyContent: "center",
  alignItems: "center"
};

const CreateDesignation = (props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const initialValues = {
    name: ""
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitHandler = (data) => {
    dispatch(addNewDesignationAction(data));
    // delay for 3 seconds
    setTimeout(() => {
      dispatch(getAllDesignationAction());
    }, 3000);

    handleClose();
  };

  return (
    <div>
      <Button
        size="large"
        startIcon={props?.icon || <AddIcon />}
        variant="contained"
        onClick={handleOpen}>
        {props?.buttonTitle}
      </Button>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth>
        <Box sx={style}>
          <Box className={styles.full_form_container}>
            <Typography
              className={styles.top_heading}
              variant="h3"
              component="h3">
              Add New Designation
            </Typography>
            <Divider light />
            <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
              {(formik) => {
                return (
                  <Form>
                    <Box className={styles.issue_form_container}>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={styles.form_heading}>
                        Name of new Designation
                      </Typography>
                      <Field
                        as={OutlinedInput}
                        placeholder="Name"
                        fullWidth
                        className={styles.input_field_description}
                        valueData={formik.values.designationId}
                        name="name"
                        size="small"
                        multiline={true}
                        spellCheck="false"
                      />

                      <Divider light />
                      <Box className={styles.button_container}>
                        <Button
                          onClick={handleClose}
                          variant="outlined"
                          className={styles.cancel_btn}>
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          className={styles.disable_submit_button}>
                          Create Designation
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateDesignation;
