import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from "@mui/material";

import { ProjectsDetail } from "./ProjectsDetail";

const ProjectsModal = ({
  open,
  handleClose,
  resourceAllocation,
  onSubmit,
  onRemoveAllocation
}) => {
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle variant="h6" fontWeight="bold" fontSize="1.2rem">
        Project Allocations for {resourceAllocation?.resourceName}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {resourceAllocation &&
          resourceAllocation?.ResourceProjectDetails.length !== 0 && (
            <ProjectsDetail
              projects={resourceAllocation?.ResourceProjectDetails}
              onSubmit={onSubmit}
              onRemoveAllocation={onRemoveAllocation}
            />
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export { ProjectsModal };
