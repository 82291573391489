import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { IconButton, OutlinedInput, InputAdornment } from "@mui/material";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import styles from "./index.module.scss";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAction } from "redux/auth/actions";
import { AuthService } from "network/authService";
import { useToast } from "hooks";

function Login() {
  const { showSuccess, showError } = useToast();
  const [showPassword, setshowPassword] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  async function handleSubmitValue(data) {
    let body = {
      email: data?.email,
      password: data?.password
    };

    const loginResponse = await AuthService.loginByEmail(body);
    if (loginResponse?.code === 200) {
      showSuccess({
        message: loginResponse?.data?.message || "Loggedin Successfully "
      });
      setTimeout(() => {
        dispatch(loginAction(loginResponse?.data));
      }, 1200);
    } else {
      showError({ message: loginResponse?.message || "Login failed" });
    }
  }

  const initialValues = {
    email: "",
    password: ""
  };

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  function validationSchema() {
    return Yup.object().shape({
      email: Yup.string()
        .required("Email ID is required")
        .email("Invalid Email id")
        .trim()
        .matches(
          "[a-zA-Z0-9._!#$^&*%+-]@[nickelfox,NICKELFOX].[com,co,COM,CO]",
          "Invalid Email id"
        ),
      password: Yup.string().required("Password is required").trim()
    });
  }

  return (
    <>
      <Paper>
        <Box>
          <Box sx={{ maxWidth: "37rem" }}>
            <Typography
              variant="h2"
              component="h2"
              className={styles.main_heading}>
              Sign In
            </Typography>
          </Box>
          <Box className={styles.form_container}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmitValue}>
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <Box className={styles.textField_container}>
                    <Typography
                      variant="h2"
                      component="h2"
                      className={styles.inputlabel}>
                      Email ID
                    </Typography>
                    <Field
                      id="email"
                      as={OutlinedInput}
                      placeholder="Enter Your Email"
                      fullWidth
                      className={styles.inputfield}
                      type={"text"}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      name="email"
                    />
                    <Box className={styles.text_error}>
                      <ErrorMessage
                        data-testid="emailError"
                        name="email"
                        component="div"
                        className={styles.text_danger}
                      />
                    </Box>

                    <Typography
                      variant="h2"
                      component="h2"
                      className={styles.inputlabel}>
                      Password
                    </Typography>

                    <Field
                      id="password"
                      as={OutlinedInput}
                      placeholder="Enter Password"
                      fullWidth
                      className={styles.inputfield}
                      type={showPassword ? "text" : "password"}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      name="password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Box className={styles.text_error}>
                      <ErrorMessage
                        data-testid="passwordError"
                        name="password"
                        component="div"
                        className={styles.text_danger}
                      />
                    </Box>
                  </Box>
                  <Typography
                    variant="h2"
                    component="h2"
                    className={styles.forgot_password_label}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/forgot-password")}>
                      Forgot Password?
                    </span>
                  </Typography>
                  <Box className={styles.submit_container}>
                    <Button
                      id="signin_button"
                      type="submit"
                      variant="contained"
                      className={styles.submit_button}>
                      Sign In
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Paper>
    </>
  );
}

export default Login;
