import { createSlice } from "@reduxjs/toolkit";

const app = createSlice({
  name: "charts",
  initialState: {
    departmentStats: {},
    designationStats: {},
    projectStats: {},
    allocationStats: []
  },
  reducers: {
    setDepartmentStatsData: (state, action) => {
      state.departmentStats = action.payload;
    },
    setDesignationStatsData: (state, action) => {
      state.designationStats = action.payload;
    },
    setProjectStatsData: (state, action) => {
      state.projectStats = action.payload;
    },
    setAllocationStatsData: (state, action) => {
      state.allocationStats = action.payload;
    }
  }
});

export default app;
export const appActions = app.actions;
