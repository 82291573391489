// Sample service to make network call

import { API, NetworkManager } from "./core";

export class ResourceService {
  static async addResource(payload) {
    const instance = new NetworkManager(API.RESOURCE.ADD, payload);
    return await instance.httpRequest(true);
  }

  static async updateResource(payload, params) {
    const instance = new NetworkManager(API.RESOURCE.UPDATE, payload, params);
    return await instance.httpRequest(true);
  }

  static async deleteResource(params) {
    const instance = new NetworkManager(API.RESOURCE.DELETE, null, params);
    return await instance.httpRequest(true);
  }

  static async getAllResource(params) {
    const instance = new NetworkManager(API.RESOURCE.LIST, null, params);
    return await instance.httpRequest(true);
  }
}
