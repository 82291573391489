import React from "react";
import { useState, useEffect } from "react";

const Timer = (props) => {
  const { initialMinute = 0, initialSeconds = 10 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <span>
      {minutes === 0 && seconds === 0 ? (
        "0"
      ) : (
        <span>{seconds < 10 ? `${seconds}` : seconds}</span>
      )}
      {seconds === 0 && props.count !== 0 ? props.buttonDisable(false) : null}
      {seconds === 0 ? props.timestate(false) : null}
    </span>
  );
};

export default Timer;
