// Sample service to make network call

import { API, NetworkManager } from "./core";

export class DesignationService {
  static async addDesignation(payload) {
    const instance = new NetworkManager(API.DESIGNATION.ADD, payload);
    return await instance.httpRequest(true);
  }

  static async updateDesignation(payload, params) {
    const instance = new NetworkManager(
      API.DESIGNATION.UPDATE,
      payload,
      params
    );
    return await instance.httpRequest(true);
  }

  static async deleteDesignation(params) {
    const instance = new NetworkManager(API.DESIGNATION.DELETE, null, params);
    return await instance.httpRequest(true);
  }
}
