import React from "react";
import { Box } from "@mui/material";
import styles from "./EditDetail.module.scss";
import Heading from "components/common-component/Heading";
import ProfileEdit from "components/profile-edit/ProfileEdit";
import Breadcrumb from "components/common-component/Breadcrumbs";

function AdminProfileEdit() {
    let pathRoute = [
        { name: "Personal Details", path: `/profile` },
        { name: "Edit Details" }
    ];
    return (
        <>
            <Box className={styles.outer_container}>
                <Heading mainHeading="Personal Details" />
                <Breadcrumb pathRoute={pathRoute}></Breadcrumb>
                  <ProfileEdit/>
            </Box>
        </>
    )
}

export default AdminProfileEdit;