import React from 'react'
import { CardMedia, Box } from "@mui/material";
import NoDataImg from "assets/images/backgrounds/page_img.png";
import styles from "./NoDataFound.module.scss";
function NoDataFound({errorMsg}) {
  return (
    <Box className={styles.outer_container}>
      <Box className={styles.img_container}>
        <CardMedia component="img" image={NoDataImg} alt="img" />
      </Box>
      <Box className={styles.text_container}>{errorMsg}</Box>
    </Box>
  );
}

export default NoDataFound;