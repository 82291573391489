import { createSlice } from "@reduxjs/toolkit";

const app = createSlice({
  name: "forgotPassword",
  initialState: {
    forgotPassword: {
      count: 0,
      email: ""
    }
  },
  reducers: {
    setForgotPasswordData: (state, action) => {
      state.forgotPassword = action.payload;
    },
    setCount: (state, action) => {
      state.forgotPassword.count = action.payload;
    },
  }
});

export default app;
export const appActions = app.actions;
