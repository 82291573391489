// Export all routes that should be in the side menu
import React from "react";
import HomeIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import WorkIcon from "@mui/icons-material/Work";

class MenuPath {
  constructor(title, icon, route) {
    this.title = title;
    this.icon = icon;
    this.route = route;
  }
}

export const DashboardMenus = [
  new MenuPath(
    "Dashboard",
    <HomeIcon />,
    "/dashboard",
    ["superadmin"],
    "dashboard"
  ),
  new MenuPath(
    "Allocations",
    <PeopleIcon />,
    "/allocation",
    "resourceList"
  ),
  new MenuPath("Resources", <PeopleIcon />, "/resources", "resources"),
  new MenuPath("Panel", <WorkIcon />, "/projects", "projects")
];
