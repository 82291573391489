import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";
import NfXMobLogo from "assets/images/nfx_mobile.png";
import styles from "./publicLayout.module.scss";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import NfxLogo from "assets/images/backgrounds/nfx_logo.png";
import { useHistory , useLocation} from "react-router-dom";
export default function PublicLayout({ children }) {
  const location = useLocation()
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("900"));

  return (
    <Box>
        <Box className={styles.full_outer_wrapper}>
        <Grid container className={styles.outer_wrapper}>
          <Grid item lg={4.5} xl={4.5} md={5} sm={12} xs={12}>
            <Box className={styles.outer_container}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push("/")
                }}>
                <Box className={styles.inner_container}>
                  <Box>
                    <CardMedia
                      className={styles.logoImg}
                      component="img"
                      image={NfxLogo}
                      alt="nickelfox"
                    />
                  </Box>
                  <Box className={styles.logo_text_container}>
                    <Box className={styles.logo_main_heading}>Nickelfox</Box>
                    <Box className={styles.logo_subheading}>
                      NFX Resource Management
                    </Box>
                  </Box>
                </Box>
              </div>
              {
                location.pathname=="/forgot-password" ? 
             <Box className={styles.header_text_container}>
                <Typography
                  variant="h1"
                  component="h1"
                  className={styles.heading_primary_main}>
                  Can’t Recollect your Password?
                </Typography>
                <Typography
                  variant="h4"
                  component="h4"
                  className={styles.heading_primary_sub}>
                  We have got you covered! Enter your registered ID and then check your mail for the Password Reset Link.
                </Typography>
             </Box> :  
             <Box className={styles.header_text_container}>
                <Typography
                  variant="h1"
                  component="h1"
                  className={styles.heading_primary_main}>
                  Track and Manage Resource Workload.
                </Typography>
                <Typography
                  variant="h4"
                  component="h4"
                  className={styles.heading_primary_sub}>
                  {/* Track all your IT related issues. */}
                </Typography>
              </Box>
              }
            </Box>
          </Grid>
          <Grid
            item
            lg={6.5}
            xl={6.5}
            md={7}
            sm={12}
            xs={12}
            className={styles.grid_body_container}>
            <Box className={styles.outer_content_wrapper}>
              <Box className={styles.content_container}>
                {matches && (
                  <Box>
                    <CardMedia
                      className={styles.mob_logoImg}
                      component="img"
                      image={NfXMobLogo}
                      alt="nickelfox"
                    />
                  </Box>
                )}

                {children}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
