import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjectsAction } from "redux/resource-and-project/actions";

const useProjectChoices = () => {
  const dispatch = useDispatch();
  const { projectData: projectChoices } = useSelector(
    (state) => state.resourceAndProject
  );

  const refetch = () => {
    dispatch(getAllProjectsAction());
  };

  React.useEffect(() => {
    refetch();
  }, []);

  return {
    projectChoices,
    refetch
  };
};

export { useProjectChoices };
