import React from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Chip,
  Button,
  IconButton,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSkillChoices } from "hooks";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const parseDate = (date) => {
  if (!date) {
    return "";
  }
  const d = new Date(date);
  return `${d.getDate()} ${d.toLocaleString("default", {
    month: "short"
  })} ${d.getFullYear()}`;
};

const processProjects = (projects) => {
  return projects?.map((project) => ({
    ...project,
    projectTech: project.projectTech || [],
    isEditing: false
  }));
};

const convertToFormData = (project) => {
  return {
    resourceUserId: project.ResourceUserUuid,
    name: project.Project?.name,
    allocationId: project.uuid,
    projectId: project.Project?.uuid,
    projectTech: project.projectTech,
    billable: project.billable,
    billablePercent: project.billablePercent,
    allocatedPercent: project.allocatedPercent,
    availabilityForDeploy : project.availabilityForDeploy,
    startedWorkingDate: project.startedWorkingDate,
    endWorkingDate: project.endWorkingDate
  };
 
};

const ProjectsDetail = ({ projects, onSubmit, onRemoveAllocation }) => {
  const { skillChoices } = useSkillChoices();

  const [projectList, setProjectList] = React.useState(
    processProjects(projects)
  );

  const handleEdit = (index) => {
    const updatedProjects = [...projectList];
    updatedProjects[index].isEditing = true;
    setProjectList(updatedProjects);
  };

  const handleCancel = (index) => {
    const updatedProjects = [...projectList];
    updatedProjects[index].isEditing = false;
    setProjectList(updatedProjects);
  };

  const handleSave = (index) => {
    const updatedProjects = [...projectList];
    updatedProjects[index].isEditing = false;
    setProjectList(updatedProjects);
    onSubmit(
      convertToFormData(updatedProjects[index]),
      updatedProjects[index].uuid
    );
  };

  const handleRemove = (index, id) => {
    const updatedProjects = [...projectList];
    updatedProjects.splice(index, 1);
    setProjectList(updatedProjects);
    onRemoveAllocation(id);
  };

  const handleChange = (index, field, value) => {
    const updatedProjects = [...projectList];
    updatedProjects[index][field] = value;
    setProjectList(updatedProjects);
  };
  console.log("projectList===>", projectList)
  return (
    <Box>
      {projectList?.map((project, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="subtitle1" fontWeight="bold">
                {project?.Project?.name ?? "---"}
              </Typography>

              {project.isEditing && (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
                    startIcon={<CancelIcon />}
                    variant="text"
                    color="secondary"
                    onClick={() => handleCancel(index)}
                    sx={{ ml: 1 }}>
                    cancel
                  </Button>
                  <Button
                    startIcon={<SaveIcon />}
                    variant="outlined"
                    color="primary"
                    onClick={() => handleSave(index)}>
                    Save
                  </Button>
                </Box>
              )}
              {!project.isEditing && (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <IconButton
                    size="small"
                    onClick={() => handleEdit(index)}
                    sx={{ ml: 1 }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => handleRemove(index, project.uuid)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body1">Billable</Typography>
            </Grid>
            <Grid item xs={8}>
              {project.isEditing ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={project.billable}
                      onChange={(event) =>
                        handleChange(index, "billable", event.target.checked)
                      }
                      name="billable"
                      color="primary"
                    />
                  }
                  label="Is Billable"
                />
              ) : (
                <Typography variant="body1">
                  {project.billable ? "Billable" : "Non Billable"}
                </Typography>
              )}
            </Grid>
            {project.billable && (
              <Grid item xs={4}>
                <Typography variant="body1">Billable Percent</Typography>
              </Grid>
            )}
            {project.billable && (
              <Grid item xs={8}>
                {project.isEditing ? (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-billable">
                      Billable Percent
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-billable"
                      placeholder="eg. 50"
                      label="Billable Percent"
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      aria-describedby="outlined-billable-helper-text"
                      inputProps={{
                        "aria-label": "billable"
                      }}
                      fullWidth
                      value={project.billablePercent}
                      onChange={(event) =>
                        handleChange(
                          index,
                          "billablePercent",
                          parseFloat(event.target.value).toFixed(1)
                        )
                      }
                    />
                    <FormHelperText id="outlined-billable-helper-text">
                      Percentage
                    </FormHelperText>
                  </FormControl>
                ) : (
                  <Typography variant="body1">
                    {project.billablePercent}%
                  </Typography>
                )}
              </Grid>
            )}
            <Grid item xs={4}>
              <Typography variant="body1">Allocated Percent</Typography>
            </Grid>
            <Grid item xs={8}>
              {project.isEditing ? (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-allocated">
                    Allocated Percent
                  </InputLabel>
                  <OutlinedInput
                    placeholder="eg. 50"
                    id="outlined-adornment-allocated"
                    label="Allocated Percent"
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                    aria-describedby="outlined-allocated-helper-text"
                    inputProps={{
                      "aria-label": "allocated"
                    }}
                    fullWidth
                    value={project.allocatedPercent}
                    onChange={(event) =>
                      handleChange(
                        index,
                        "allocatedPercent",
                        parseFloat(event.target.value).toFixed(1)
                      )
                    }
                  />
                  <FormHelperText id="outlined-allocated-helper-text">
                    Percentage
                  </FormHelperText>
                </FormControl>
              ) : (
                <Typography variant="body1">
                  {project.allocatedPercent}%
                </Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">Project Availability for Deployment</Typography>
            </Grid>
            <Grid item xs={8}>
              {project.isEditing ? (
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-allocated">
                   Project Availability for Deployment
                  </InputLabel>
                  <OutlinedInput
                    placeholder="eg. 50"
                    id="outlined-adornment-allocated"
                    label="Project Availability for Deployment"
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                    aria-describedby="outlined-allocated-helper-text"
                    inputProps={{
                      "aria-label": "allocated"
                    }}
                    fullWidth
                    value={project.availabilityForDeploy}
                    onChange={(event) =>
                      handleChange(
                        index,
                        "availabilityForDeploy",
                        parseFloat(event.target.value).toFixed(1)
                      )
                    }
                  />
                  <FormHelperText id="outlined-allocated-helper-text">
                    Percentage
                  </FormHelperText>
                </FormControl>
              ) : (
                <Typography variant="body1">
                  {project.availabilityForDeploy}%
                </Typography>
              )}
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={4}>
                <Typography variant="body1">Started Working Date</Typography>
              </Grid>
              <Grid item xs={8}>
                {project.isEditing ? (
                  <DesktopDatePicker
                    label="Start Working Date"
                    maxDate={project.endWorkingDate}
                    inputFormat="yyyy-MM-dd"
                    value={project.startedWorkingDate}
                    onChange={(date) =>
                      handleChange(index, "startedWorkingDate", date)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                ) : (
                  <Typography variant="body1">
                    {parseDate(project.startedWorkingDate)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1">End Working Date</Typography>
              </Grid>
              <Grid item xs={8}>
                {project.isEditing ? (
                  <DesktopDatePicker
                    label="End Working Date"
                    minDate={project.startedWorkingDate}
                    inputFormat="yyyy-MM-dd"
                    value={project.endWorkingDate || null}
                    onChange={(date) =>
                      handleChange(index, "endWorkingDate", date)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                ) : (
                  <Typography variant="body1">
                    {parseDate(project.endWorkingDate)}
                  </Typography>
                )}
              </Grid>
            </LocalizationProvider>
            <Grid item xs={4}>
              <Typography variant="body1">Project Technologies</Typography>
            </Grid>
            <Grid item xs={8}>
              {project.isEditing ? (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={skillChoices}
                  getOptionLabel={(option) => option.name}
                  value={skillChoices.filter((skill) =>
                    project.projectTech.includes(skill.name)
                  )}
                  onChange={(event, value) => {
                    handleChange(
                      index,
                      "projectTech",
                      value.map((item) => item.name)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Project Tech"
                      placeholder="eg. Java"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              ) : (
                project?.projectTech?.map((tech) => (
                  <Chip key={tech} label={tech} sx={{ mr: 1 }} />
                ))
              )}
            </Grid>
            {index !== projects.length - 1 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export { ProjectsDetail };
