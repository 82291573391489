import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllExpertiseAction } from "redux/resource-and-project/actions";

const useSkillChoices = () => {
  const dispatch = useDispatch();
  const { expertise: skillChoices } = useSelector(
    (state) => state.resourceAndProject
  );

  const refetch = () => {
    dispatch(getAllExpertiseAction());
  };

  React.useEffect(() => {
    refetch();
  }, []);

  return {
    skillChoices,
    refetch
  };
};

export { useSkillChoices };
