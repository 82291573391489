import { createSlice } from "@reduxjs/toolkit";

const app = createSlice({
  name: "resourceAndProject",
  initialState: {
    resourceData: [],
    projectData: [],
    projectResourceData: [],
    departmentsData: [],
    designationsData: [],
    expertise: [],
  },
  reducers: {
    setResourceData: (state, action) => {
      state.resourceData = action.payload;
    },
    setProjectData: (state, action) => {
      state.projectData = action.payload;
    },
    setResourceProjectData: (state, action) => {
      state.projectResourceData = action.payload;
    },
    setDepartmentsData: (state, action) => {
      state.departmentsData = action.payload;
    },
    setDesignationsData: (state, action) => {
      state.designationsData = action.payload;
    },
    setExpertiseData: (state, action) => {
      state.expertise = action.payload;
    },
  }
});

export default app;
export const appActions = app.actions;
