import React from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  InputAdornment,
  FormControl,
  OutlinedInput,
  FormHelperText,
  InputLabel
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useSkillChoices, useResourceChoices, useProjectChoices } from "hooks";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
const initialProjectValues = {
  billablePercent: null,
  billable: false,
  allocatedPercent: null,
  startedWorkingDate: new Date(),
  endWorkingDate: "",
  projectTech: [],
  resourceUserId: "",
  projectId: "",
  availabilityForDeploy: null
};

const CreateAllocationModal = ({ open, handleClose, handleSave }) => {
  const [formData, setFormData] = React.useState(initialProjectValues);
  const { skillChoices } = useSkillChoices();
  const { resourceChoices } = useResourceChoices();
  const { projectChoices } = useProjectChoices();

  const handleChange = (field, value) => {

    const newFormData = { ...formData, [field]: value };
    setFormData(newFormData);
  };

  const handleSubmit = () => {
    const newFormData = {
      ...formData,
      allocatedPercent: formData.allocatedPercent
        ? parseFloat(formData.allocatedPercent).toFixed(1)
        : 0,
      billablePercent: formData.billablePercent
        ? parseFloat(formData.billablePercent).toFixed(1)
        : 0,
      availabilityForDeploy: formData.availabilityForDeploy
        ? parseFloat(formData.availabilityForDeploy).toFixed(1)
        : 0,
      projectTech: formData.projectTech.map((skill) => skill.name)
    };
    handleSave(newFormData);
    console.log("handlesave", newFormData)
  };

  const onClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    handleClose();
  };

  React.useEffect(() => {
    if (open) {
      setFormData(initialProjectValues);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Typography variant="h4">Allocate Resource</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={resourceChoices}
                getOptionLabel={(option) => option.resourceName}
                value={resourceChoices.find(
                  (resource) => resource.uuid === formData.resourceUserId
                )}
                onChange={(event, value) =>
                  handleChange("resourceUserId", value?.uuid)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Resource"
                    placeholder="eg. John Doe"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={projectChoices}
                getOptionLabel={(option) => option.name}
                value={projectChoices.find(
                  (project) => project.uuid === formData.projectId
                )}
                onChange={(event, value) =>
                  handleChange("projectId", value?.uuid)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project"
                    placeholder="eg. Project 57"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={formData.billable ? 4 : 6}
              alignSelf="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.billable}
                    onChange={(event) =>
                      handleChange("billable", event.target.checked)
                    }
                    name="billable"
                    color="primary"
                  />
                }
                label="Is Billable"
              />
            </Grid>
            {formData.billable && (
              <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-billable">
                    Billable Percent
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-billable"
                    placeholder="eg. 50"
                    label="Billable Percent"
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                    aria-describedby="outlined-billable-helper-text"
                    inputProps={{
                      "aria-label": "billable",
                      type: "number",
                      min: 0,
                      max: 100
                    }}
                    fullWidth
                    value={formData.billablePercent}
                    onChange={(event) =>
                      handleChange("billablePercent",  parseFloat(event.target.value).toFixed(1))
                    }
                  />
                  <FormHelperText id="outlined-billable-helper-text">
                    Percentage
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={formData.billable ? 4 : 6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-allocated">
                  Allocated Percent
                </InputLabel>
                <OutlinedInput
                  placeholder="eg. 50"
                  id="outlined-adornment-allocated"
                  label="Allocated Percent"
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  aria-describedby="outlined-allocated-helper-text"
                  inputProps={{
                    "aria-label": "allocated",
                    type: "number",
                    min: 0,
                    max: 100
                  }}
                  fullWidth
                  value={formData.allocatedPercent}
                  onChange={(event) =>
                    handleChange("allocatedPercent", parseFloat(event.target.value).toFixed(1))
                  }
                />
                <FormHelperText id="outlined-allocated-helper-text">
                  Percentage
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-availabilityForDeploy">
                  Project Availability For Deployment
                </InputLabel>
                <OutlinedInput
                  placeholder="eg. 50"
                  id="outlined-adornment-availabilityForDeploy"
                  label="Project Availability For Deployment"
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  aria-describedby="outlined-availabilityForDeploy-helper-text"
                  inputProps={{
                    "aria-label": "availabilityForDeploy",
                    type: "number",
                    min: 0,
                    max: 100
                  }}
                  fullWidth
                  value={formData.availabilityForDeploy}
                  onChange={(event) =>
                    handleChange("availabilityForDeploy",parseFloat(event.target.value).toFixed(1))
                  }
                />
                <FormHelperText id="outlined-availabilityForDeploy-helper-text">
                  Percentage
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={skillChoices}
                getOptionLabel={(option) => option.name}
                value={skillChoices.filter(
                  (skill) => formData.projectTech.includes(skill)
                  // formData.projectTech.includes(skill.uuid)
                )}
                onChange={(event, value) =>
                  handleChange(
                    "projectTech",
                    value
                    // value.map((skill) => skill.uuid)
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project Tech"
                    placeholder="eg. Java"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Start Working Date"
                  inputFormat="yyyy-MM-dd"
                  views={["year", "month", "day"]}
                  openTo="year"
                  maxDate={formData.endWorkingDate}
                  value={formData.startedWorkingDate}
                  onChange={(date) => handleChange("startedWorkingDate", date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="End Working Date"
                  inputFormat="yyyy-MM-dd"
                  minDate={formData.startedWorkingDate}
                  views={["year", "month", "day"]}
                  value={formData.endWorkingDate || null}
                  onChange={(date) => handleChange("endWorkingDate", date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="large" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          type="submit"
          onClick={handleSubmit}>
          Allocate Resource
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { CreateAllocationModal };
