import { all, fork } from "redux-saga/effects";
import auth from "./auth/saga";
import resourceAndProject from "./resource-and-project/saga";
import charts from "./charts/saga";

export default function* rootSaga() {
  yield all([fork(auth)]);
  yield all([fork(resourceAndProject)]);
  yield all([fork(charts)]);
}
