import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  getDepartmentStatsAction,
  getAllocationStatsAction,
  getDesignationStatsAction,
  getProjectStatsAction
} from "./actions";
import chartSlice from "./slice";

const baseUrl = `${process.env.REACT_APP_API_URL}/v1`;

export function* getDepartmentStats() {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/department/stats`);

    yield put(chartSlice.actions.setDepartmentStatsData(data.data));
  } catch (e) {
    e;
  }
}

export function* getDesignationStats() {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/designation/stats`);

    yield put(chartSlice.actions.setDesignationStatsData(data.data));
  } catch (e) {
    e;
  }
}

export function* getAllocationStats() {
  try {
    const { data } = yield call(
      axios.get,
      `${baseUrl}/resourceAllocation/stats`
    );

    yield put(chartSlice.actions.setAllocationStatsData(data.data));
  } catch (e) {
    e;
  }
}

export function* getProjectStats() {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/project/stats`);

    yield put(chartSlice.actions.setProjectStatsData(data.data));
  } catch (e) {
    e;
  }
}

export default function* charts() {
  yield takeLatest(getDepartmentStatsAction.type, getDepartmentStats);
  yield takeLatest(getDesignationStatsAction.type, getDesignationStats);
  yield takeLatest(getAllocationStatsAction.type, getAllocationStats);
  yield takeLatest(getProjectStatsAction.type, getProjectStats);
}
