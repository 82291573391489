import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Timer from "components/resetlink";
import { AuthService } from "network/authService";
import { updateCountAction } from "redux/auth/actions";
import styles from "./resetlinksent.module.scss";

function ResetLinkSent() {
  const history = useHistory();
  const dispatch = useDispatch();
  const emailSent = useSelector((state) => state.forgotPassword.forgotPassword);

  useEffect(() => {
    checkEmail();
  }, []);
  function checkEmail() {
    if (!emailSent?.email.length > 0) {
      // commentted out to let API resolve
      // history.push("/login");
    }
  }

  const [timeState, setTimeState] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const takeCount = useSelector((state) => state.forgotPassword.forgotPassword);

  const timerCount = takeCount.count;
  let pendingCount = 4 - timerCount;

  async function timerHandler() {
    let body = { email: emailSent?.email };
    let forgotPassword = await AuthService.forgotPassword(body);

    if (forgotPassword.code === 200 && forgotPassword.success === true) {
      let count = forgotPassword?.data?.forgotLimit;
      dispatch(updateCountAction(count));
      setTimeState(true);
      setButtonDisable(true);
    } else {
      console.log("error");
    }
  }
  return (
    <Paper>
      <Box className={styles.outer_container}>
        <Typography variant="h2" component="h2" className={styles.main_heading}>
          Reset Link Sent
        </Typography>
        {timerCount === 4 ? (
          <Typography
            Typography
            variant="h5"
            component="h5"
            className={styles.sub_heading}>
            You have reached maximum limit to request password reset link.
            Please try after 5 minutes for now
          </Typography>
        ) : (
          <Typography
            Typography
            variant="h5"
            component="h5"
            className={styles.sub_heading}>
            We have sent you a mail to reset your password. Please check your
            inbox or spam folder for the mail.
            <br />
            <br />
            If not recieved you can{" "}
            {pendingCount == 1 ? "" : <span>click on resend link</span>}
            {pendingCount == 3 ? (
              <span>
                &nbsp;for&nbsp;
                <span className={styles.inline_styling}>
                  {pendingCount} times
                </span>
                .
              </span>
            ) : pendingCount == 2 ? (
              <span>
                &nbsp;after&nbsp;
                {timeState ? (
                  <Timer
                    timestate={setTimeState}
                    buttonDisable={setButtonDisable}
                    count={timerCount}></Timer>
                ) : (
                  "10"
                )}
                &nbsp;seconds for &nbsp;
                <span className={styles.inline_styling}>
                  {pendingCount} times
                </span>
                .
              </span>
            ) : pendingCount == 1 ? (
              <span>
                {timeState ? (
                  <span>
                    {" "}
                    request after{" "}
                    <Timer
                      timestate={setTimeState}
                      buttonDisable={setButtonDisable}
                      count={timerCount}></Timer>{" "}
                    &nbsp;seconds for the last time&nbsp;
                  </span>
                ) : (
                  "request for the last time"
                )}
              </span>
            ) : null}
          </Typography>
        )}
        {timerCount === 4 ? (
          <Button
            className={styles.login_button}
            onClick={() => history.push("/login")}
            variant="contained"
            disableRipple>
            Login
          </Button>
        ) : (
          <Button
            disabled={buttonDisable}
            onClick={timerHandler}
            className={
              buttonDisable ? styles.resend_button : styles.success_button
            }
            variant="contained">
            Resend Link
          </Button>
        )}
      </Box>
    </Paper>
  );
}

export default ResetLinkSent;
