import _ from "lodash";
import React from "react";
import {
  Autocomplete,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import {
  useDepartmentChoices,
  useDesignationChoices,
  useProjectChoices,
  useResourceChoices,
  useSkillChoices
} from "hooks";
import FilterListIcon from "@mui/icons-material/FilterList";

const defaultFilter = {
  search: "",
  designation: [],
  department: [],
  resourceUser: [],
  project: [],
  expertise: [],
  billable: null
};

const parseFilter = (filter) => {
  Object.keys(filter).forEach((key) => {
    if (Array.isArray(filter[key])) {
      filter[key] = filter[key].map((item) => item?.uuid);
    }
  });

  let query = Object.keys(filter)
    .filter((key) => {
      return filter?.[key] && filter?.[key]?.length > 0;
    })
    .map((key) => {
      if (Array.isArray(filter[key])) {
        return `${key}=${filter[key].join(",")}`;
      } else {
        return `${key}=${filter[key]}`;
      }
    })
    .join("&");
  if (typeof filter?.billable === "boolean") {
    query += `&billable=${filter.billable}`;
  }

  return query;
};

const AllocationFilter = ({ filter, onAppplyFilter }) => {
  const { skillChoices } = useSkillChoices();
  const { designationChoices } = useDesignationChoices();
  const { departmentChoices } = useDepartmentChoices();
  const { projectChoices } = useProjectChoices();
  const { resourceChoices } = useResourceChoices();
  const [localFilter, setLocalFilter] = React.useState(filter);
  const [isFilterApplied, setIsFilterApplied] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (!_.isEqual(filter, localFilter)) {
      setLocalFilter(filter);
    }
  }, [filter]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setIsModalOpen(false);
  };

  const handleApplyFilter = React.useCallback(() => {
    // clone a deep copy of localFilter
    const localFilterClone = _.cloneDeep(localFilter);
    const query = parseFilter(localFilterClone);
    onAppplyFilter(localFilter, query);
    setIsFilterApplied(true);
    setIsModalOpen(false);
  }, [localFilter]);

  const handleClearFilter = () => {
    setLocalFilter(defaultFilter);
  };

  const handleResetFilter = () => {
    setLocalFilter(defaultFilter);
    setIsFilterApplied(false);
    onAppplyFilter(defaultFilter, "");
  };

  const handleMultiSelectChange = (event, value, name) => {
    setLocalFilter((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <>
      <IconButton
        onClick={() => setIsModalOpen(true)}
        size="large"
        color={isFilterApplied ? "primary" : "default"}
        aria-label="upload picture"
        component="span">
        <FilterListIcon />
      </IconButton>
      <Dialog open={isModalOpen} onClose={handleClose}>
        <DialogTitle>Filters</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={designationChoices}
                getOptionLabel={(option) => option.name}
                value={designationChoices.filter((designation) =>
                  localFilter.designation.includes(designation)
                )}
                onChange={(event, value) =>
                  handleMultiSelectChange(event, value, "designation")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Designation"
                    placeholder="Select Designation"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={departmentChoices}
                getOptionLabel={(option) => option.name}
                value={departmentChoices.filter((department) =>
                  localFilter.department.includes(department)
                )}
                onChange={(event, value) => {
                  console.log("event", event);
                  console.log("value", value);
                  handleMultiSelectChange(event, value, "department");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Department"
                    placeholder="Select Department"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={resourceChoices}
                getOptionLabel={(option) => option.resourceName}
                value={resourceChoices.filter((resource) =>
                  localFilter.resourceUser.includes(resource)
                )}
                onChange={(event, value) =>
                  handleMultiSelectChange(event, value, "resourceUser")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Resource"
                    placeholder="Select Resource"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={projectChoices}
                getOptionLabel={(option) => option.name}
                value={projectChoices.filter((project) =>
                  localFilter.project.includes(project)
                )}
                onChange={(event, value) =>
                  handleMultiSelectChange(event, value, "project")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project"
                    placeholder="Select Project"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={skillChoices}
                getOptionLabel={(option) => option.name}
                value={skillChoices.filter((skill) =>
                  localFilter.expertise.includes(skill)
                )}
                onChange={(event, value) =>
                  handleMultiSelectChange(event, value, "expertise")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Expertise"
                    placeholder="Select Expertise"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={localFilter.billable === null}
                    onChange={() => {
                      setLocalFilter((prev) => ({
                        ...prev,
                        billable: null
                      }));
                    }}
                    name="all"
                    color="primary"
                  />
                }
                label="All"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={localFilter.billable === true}
                    onChange={() => {
                      setLocalFilter((prev) => ({
                        ...prev,
                        billable: true
                      }));
                    }}
                    name="billable"
                    color="primary"
                  />
                }
                label="Billable"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={localFilter.billable === false}
                    onChange={() => {
                      setLocalFilter((prev) => ({
                        ...prev,
                        billable: false
                      }));
                    }}
                    name="nonBillable"
                    color="primary"
                  />
                }
                label="Non Billable"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleClearFilter} color="secondary">
            Clear
          </Button>
          <Button onClick={handleResetFilter} color="primary">
            Reset
          </Button>
          <Button
            variant="outlined"
            onClick={handleApplyFilter}
            color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { AllocationFilter, parseFilter };
