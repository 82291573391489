import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDesignationAction } from "redux/resource-and-project/actions";

const useDesignationChoices = () => {
  const dispatch = useDispatch();
  const { designationsData: designationChoices } = useSelector(
    (state) => state.resourceAndProject
  );

  const refetch = () => {
    dispatch(getAllDesignationAction());
  };

  React.useEffect(() => {
    refetch();
  }, []);

  return {
    designationChoices,
    refetch
  };
};

export { useDesignationChoices };
