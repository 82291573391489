import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { userDetailsAction } from "redux/auth/actions";

const useUserDetails = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.auth?.userDetails);

  const refetch = () => {
    dispatch(userDetailsAction());
  };

  React.useEffect(() => {
    refetch();
  }, []);

  return {
    userDetails,
    refetch
  };
};

export { useUserDetails };
