import React from "react";
import { Box, Typography } from "@mui/material";

function ChartTitle({ title, textAlign="left" , marginBottom="0rem"}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",        
      }}>
      <Typography
        variant="h4"
        sx={{
          marginBottom : marginBottom,
          fontWeight: "bold",
          color: "#727372",
          textAlign: textAlign,
          width: { md: "100%" },
        }}>
        {title}
      </Typography>
    </Box>
  );
}

export { ChartTitle };
