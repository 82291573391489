export function capitaliseNameInitials(value) {
  if (value) {
    let newVal = value.split(" ");
    let result = "";
    for (let i = 0; i < newVal.length; i++) {
      newVal[i] = newVal[i].replace(
        newVal[i].charAt(0),
        newVal[i].charAt(0).toUpperCase()
      );
    }
    for (let i = 0; i < newVal.length; i++) {
      result = result + newVal[i] + " ";
    }
    return result.trim();
  } else {
    return "";
  }
}

export function parseYearData(value) {
  if (value) {
    try {
      const years = parseInt(value);
      if (years === 1) {
        return `${years} year`;
      } else {
        return `${years} years`;
      }
    } catch (error) {
      return "No Experience";
    }
  } else {
    return "No Experience";
  }
}

export function parsePercentData(value) {
  if (value) {
    try {
      const percent = parseFloat(value).toFixed(2);
      return percent;
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
}

export function parseDateData(value) {
  if (value) {
    try {
      const date = new Date(value);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}/${month}/${day}`;
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
}
