import { createSlice } from "@reduxjs/toolkit";

const app = createSlice({
  name: "app",
  initialState: {
    loading: false,
    message: {
      text: "",
      variant: ""
    },
    isLoggedIn: false
  },
  reducers: {
    setLoadingData: (state, action) => {
      state.loading = action.payload;
    },
    setMessageData: (state, action) => {
      state.message = action.payload;
    },
    setIsLoggedInData: (state, action) => {
      state.isLoggedIn = action.payload;
    }
  }
});

export default app;
export const appActions = app.actions;
