import React, { useState, useEffect } from "react";
import {
  styled,
  useTheme,
  Drawer,
  Box,
  Toolbar,
  Typography,
  Divider,
  List
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import MuiAppBar from "@mui/material/AppBar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem, { listItemClasses } from "@mui/material/ListItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useHistory } from "react-router-dom";
import styles from "./privateLayout.module.scss";
import NfxLogo from "../assets/images/nfx_logo.png";
// import NotificationIcon from "assets/images/icons/notification.svg";
import Avatar from "assets/images/icons/avatar.png";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DashboardMenus } from "router/routes/dashboardRoutes";
import { useDispatch } from "react-redux";
import appSlice from "redux/app/slice";
import authSlice from "redux/auth/slice";
import { userDetail } from "hooks/state";
import { capitaliseNameInitials } from "components/utilityFunction/UtilityFunction";
import { getAllProjectsAction } from "redux/resource-and-project/actions";
import { getAllDepartmentsAction } from "redux/resource-and-project/actions";
import { getAllDesignationAction } from "redux/resource-and-project/actions";
const drawerWidth = 250;
// import { loginAction } from "redux/auth/actions";
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end"
}));

export default function PrivateLayout({ children }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const user = userDetail();
  const history = useHistory();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const responsiveMatch = useMediaQuery(theme.breakpoints.down("1026"));

  useEffect(() => {
    clearFilter();
    getCount();
  }, []);

 async function getCount(){
    getAllProjectsAction();
    getAllDepartmentsAction()
    getAllDesignationAction()
 }
  function clearFilter() {}
  const opens = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    dispatch(appSlice.actions.setIsLoggedInData(false));
    dispatch(authSlice.actions.setUserData({}));
  };

  const handleProfile = () => {
    history.push("/profile")
    setAnchorEl(null);
  };

  const handlePassword = () => {
    history.push("/update-password")
    setAnchorEl(null);
  };

  // function handleNotification() {
  //   history.push(`/notification`);
  // }

  const drawer = (
    <>
      <DrawerHeader>
        <Box className={styles.sidebar_logo_container}>
          <Box sx={{ display: "flex" }}>
            <Box>
              <CardMedia
                className={styles.sidebar_logo}
                component="img"
                image={NfxLogo}
                alt="nickelfox"
              />
            </Box>
            <Box sx={{ marginLeft: "1rem" }}>
              <Typography className={styles.sidebar_logo_main_heading}>
                Nickelfox
              </Typography>
              <Typography className={styles.sidebar_logo_subheading}>
                NFX Resource Management
              </Typography>
            </Box>
          </Box>
        </Box>
      </DrawerHeader>
      <Divider className={styles.sidebar_divider_style} />
      <List
        sx={{
          color: "#9D9D9D",
          width: "185px",
          height: "56px",
          marginLeft: "12%",
          marginTop: "1rem",
          "& svg": {
            fill: "#9D9D9D"
          },
          [`& .active, & .${listItemClasses.root}:hover`]: {
            color: "#FFFFFF",
            fontWeight: "bold",
            backgroundColor: "#D9001D",
            borderRadius: "8px",

            "& svg": {
              fill: "#FFFFFF"
            }
          }
        }}>
        {DashboardMenus.map((item, idx) => {
          return (
            <ListItem
              className={
                location.pathname.includes(item.route) ? "active" : " "
              }
              sx={{ width: "200px", marginBottom: "1rem" }}
              button
              key={idx}
              onClick={() => history.push(item.route)}>
              <ListItemIcon sx={{ minWidth: "40px" }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          );
        })}
      </List>
    </>
  );

  return (
    <Box
      sx={{
        display: "flex",
        overflow: "hidden",
        height: "100vh"
      }}>
      <AppBar
        position="fixed"
        open={open}
        className={styles.header_appbar}
        sx={{ marginTop: "20px" }}>
        <Box sx={{ position: "relative" }}>
          <Toolbar
            sx={{
              backgroundColor: responsiveMatch ? "#fff" : "transparent",
              justifyContent: responsiveMatch ? "space-between" : "flex-end"
            }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: responsiveMatch ? "block" : "none" }}>
              {/* sx={{ mr: 2, display: {md : 'none'} }}> */}
              <MenuIcon sx={{ color: "black" }} />
            </IconButton>
            <Box className={styles.header_box}>
              <Box sx={{ position: "relative" }}>
                {/* <CardMedia
                  className={styles.topbar_notification}
                  component="img"
                  image={NotificationIcon}
                  alt="notification"
                  onClick={handleNotification}
                /> */}
              </Box>
              <div>
                <Button
                  className={styles.topbar_button}
                  id="basic-button"
                  aria-controls={opens ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={opens ? "true" : undefined}
                  onClick={handleClick}
                  disableRipple
                  disableElevation
                  disableFocusRipple
                  endIcon={
                    opens ? (
                      <KeyboardArrowUpIcon color="secondary" />
                    ) : (
                      <KeyboardArrowDownIcon color="secondary" />
                    )
                  }>
                  <span className={styles.topbar_button_heading}>
                    {/* Super Admin */}
                    {capitaliseNameInitials(user.name)}
                  </span>
                  <Box className={styles.profilebox}>
                    <CardMedia
                      className={styles.userimg}
                      component="img"
                      image={Avatar}
                      alt="profile"
                      name="file"
                    />
                  </Box>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={opens}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button"
                  }}>
                  <Box className={styles.menu_style}>
                    <MenuItem
                      className={styles.listStyle}
                      onClick={handleProfile}>
                      Profile
                    </MenuItem>
                    <Divider className={styles.listDivider} />
                    <MenuItem
                      className={styles.listStyle}
                      onClick={handlePassword}>
                      Change Password
                    </MenuItem>
                    <Divider className={styles.listDivider} />
                    <MenuItem
                      className={styles.listStyle}
                      onClick={handleLogout}>
                      <Box className={styles.logout_box}>
                        Logout
                        <CardMedia
                          className={styles.logout_icon}
                          component="img"
                          image={NfxLogo}
                          alt="logout"
                        />
                      </Box>
                    </MenuItem>
                  </Box>
                </Menu>
              </div>
              {/* </Tooltip> */}
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
      <Drawer
        sx={{
          // display: { xs: 'block',sm:'block',md: 'none' },
          display: responsiveMatch ? "block" : "none",
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#383838",
            marginLeft: "20px",
            marginTop: "16px",
            borderRadius: "12px"
          }
        }}
        variant="temporary"
        anchor="left"
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        open={mobileOpen}>
        {drawer}
      </Drawer>
      <Drawer
        sx={{
          // display: { xs: 'none', sm: 'none',md :'block' },
          display: responsiveMatch ? "none" : "block",
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#383838",
            marginLeft: "20px",
            marginTop: "16px",
            borderRadius: "12px"
          }
        }}
        variant="permanent"
        anchor="left"
        open={mobileOpen}>
        {drawer}
      </Drawer>

      <Main open={open}>
        <DrawerHeader />
        <Box
          sx={{
            // width: "100%",
            maxHeight: "100vh"
          }}>
          {children}
        </Box>
      </Main>
    </Box>
  );
}
