import React from "react";
import { Box} from "@mui/material";
import styles from "./UpdatePassword.module.scss";
import Heading from "components/common-component/Heading";
import UpdatePassword from "components/update-password";

function AdminPasswordUpdate() {
    
    return (
        <>
            <Box className={styles.outer_container}>
                <Heading mainHeading="Change Password" />
                <UpdatePassword />
            </Box>
        </>
    )
}

export default AdminPasswordUpdate;