import React from "react";
import { Typography, Box, Paper, Button } from "@mui/material";
import styles from "./success.module.scss";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const ResetPassWordSuccess = () => {
  const history = useHistory();
  return (
    <Paper>
      <Box>
        <div className={styles.success_container}>
          <Box sx={{ height: 158 * 2 }}>
            <IconButton>
              <CheckCircleOutlineIcon
                color="success"
                className={styles.success_icon}
              />
            </IconButton>
            <Box>
              <Typography
                variant="h2"
                component="h2"
                className={styles.h2_header}>
                Password Reset
              </Typography>
              <Typography
                variant="h5"
                component="h5"
                className={styles.h5_subtitle}>
                Your password is successfully reset on Nickelfox Resource
                Management account. Click on login to get started.
              </Typography>

              <Button
                className={styles.submit_button}
                variant="contained"
                disableRipple
                onClick={() => history.push("/login")}>
                Login
              </Button>
            </Box>
          </Box>
        </div>
      </Box>
    </Paper>
  );
};

export default ResetPassWordSuccess;
