// Sample service to make network call

import { API, NetworkManager } from "./core";

export class AllocationService {
  static async addAllocation(payload) {
    const instance = new NetworkManager(API.ALLOCATION.ADD, payload);
    return await instance.httpRequest(true);
  }
}
