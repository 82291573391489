import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { TabPanel } from "components/common-component/TabPanel";
import Heading from "components/common-component/Heading";
import CreateProject from "components/modal/createProject";
import CreateDepartment from "components/modal/createDepartment";
import CreateDesignation from "components/modal/createDesignation";
import ProjectTable from "./projects/ProjectTable";
import DepartmentTable from "./department/DepartmentTable";
import DesignationTable from "./designation/DesignationTable";
import styles from "./resources.module.scss";

function Resources() {
  const [activeTab, setActiveTab] = useState(0);
  const allProjectsCount = useSelector(
    (state) => state.resourceAndProject.projectData?.length
  );
  const allDepartmentCount = useSelector(
    (state) => state.resourceAndProject.departmentsData?.length
  );
  const allDesignationCount = useSelector(
    (state) => state.resourceAndProject.designationsData?.length
  );
  // const [windowSize, setWindowSize] = useState(getWindowSize());

  const handleChange = (_, newValue) => {
    if (typeof newValue !== "number") return;
    setActiveTab(newValue);
  };

  useEffect(() => {
    function handleWindowResize() {
      // setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // function getWindowSize() {
  //   const { innerWidth, innerHeight } = window;
  //   return { innerWidth, innerHeight };
  // }

  const getActionItems = React.useCallback(() => {
    return (
      <Box sx={{ p: 1 }}>
        {activeTab === 0 && <CreateProject buttonTitle="Add new" />}
        {activeTab === 1 && <CreateDepartment buttonTitle="Add new" />}
        {activeTab === 2 && <CreateDesignation buttonTitle="Add new" />}
      </Box>
    );
  }, [activeTab]);

  return (
    <>
      <Box className={styles.outer_container}>
        <Heading mainHeading="Panel" />
        <Box className={styles.employee_list_wrapper}>
          <Box className={styles.box_container}>
            <Grid
              sx={{
                borderBottom: 1,
                borderColor: "divider"
              }}
              item
              md={12}
              id="tab-container">
              <Tabs
                value={activeTab}
                onChange={handleChange}
                sx={{ backgroundColor: "white" }}>
                <Tab label={`Projects (${allProjectsCount})`} />
                <Tab label={`Departments (${allDepartmentCount})`} />
                <Tab label={`Designations (${allDesignationCount})`} />
                <Box
                  sx={{
                    flexGrow: 1,
                    justifyContent: "flex-end",
                    display: "flex",
                    pr: 2
                  }}>
                  {getActionItems()}
                </Box>
              </Tabs>
            </Grid>
            <TabPanel value={activeTab} index={0}>
              <ProjectTable />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <DepartmentTable />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <DesignationTable />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Resources;
