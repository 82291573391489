import React from "react";
import styles from "./Heading.module.scss";
import { Box, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

function Heading(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box sx={{ marginTop: matches ? "5rem" : "1rem" }}>
        <Typography
          className={styles.employee_list_container}
          variant="h3"
          component="h3">
          {props.mainHeading}
        </Typography>
      </Box>
    </>
  );
}

export default Heading;
