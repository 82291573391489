import React from "react";
import { Box, Typography } from "@mui/material";
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  Cell,
  Legend
} from "recharts";

const COLORS = ["#00C49F", "#0088FE", "#A9A9A9"];

const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
  const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);
  return (
    <>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontSize: "1.5rem" }}>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </>
  );
};

const AllocationTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        className="custom-tooltip"
        sx={{
          px: 1,
          py: 0.5,
          borderRadius: 2,
          backgroundColor: "white",
          boxShadow: 1,
          color: "black"
        }}>
        <Typography>
          {`${payload?.[0]?.payload.count} ${truncateString(
            payload?.[0]?.name,
            32
          )}`}
        </Typography>
      </Box>
    );
  }

  return null;
};

const prepareData = (stats) => {
  const data = [];
  const noAllocationCount = stats?.resourceUserWithNoAllocationCount;
  const total = stats?.totalResourceAllocationCount;
  const allocationCount = total - noAllocationCount;
  data.push({
    name: "Allocated Resources",
    count: allocationCount,
    value: (parseInt(allocationCount) / total) * 100,
    fill: COLORS[0]
  });
  data.push({
    name: "Available Resources",
    count: noAllocationCount,
    value: (parseInt(noAllocationCount) / total) * 100,
    fill: COLORS[1]
  });

  return data;
};

export function AllocationPieChart({ stats }) {
  if (Object.keys(stats).length === 0) return null;
  const data = React.useMemo(() => prepareData(stats), [stats]);

  return (
    <ResponsiveContainer width="100%" height={500}>
      {data && (
        <PieChart>
          <Pie
            data={data}
            labelLine={false}
            label={renderCustomizedLabel}
            stroke="none"
            fill="#8884d8"
            dataKey="value">
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                style={{ outline: "none" }}
              />
            ))}
          </Pie>
          <Legend />
          <Tooltip
            content={<AllocationTooltip />}
            wrapperStyle={{ zIndex: 100, outline: "none" }}
          />
        </PieChart>
      )}
    </ResponsiveContainer>
  );
}
