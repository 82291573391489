import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useDebounce } from "hooks/utils";
import { IconButton } from "@mui/material";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.gray.main, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.gray.main, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const CloseIconWrapper = styled("span")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  color: "#A8A8A8",
  height: "100%",
  top: 0,
  right: "0.3rem",
  paddingLeft: "1.5rem",
  width: "18px",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const SearchBox = ({ placeholder, onChange }) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [closeToggle, setCloseToggle] = useState(false);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  React.useEffect(() => {
    onChange(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length > 0) {
      setCloseToggle(true);
    } else {
      setCloseToggle(false);
    }
  };

  function handleClear() {
    setSearchValue("");
    setCloseToggle(false);
  }

  return (
    <Box>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={placeholder}
          inputProps={{ "aria-label": "search" }}
          value={searchValue}
          onChange={(e) => handleSearch(e)}
        />
        {closeToggle ? (
          <CloseIconWrapper>
            <IconButton onClick={handleClear}>
              <CloseIcon
                size="small"
                sx={{ height: "15px", width: "15px" }}
              />
            </IconButton>
          </CloseIconWrapper>
        ) : null}
      </Search>
    </Box>
  );
};

export { SearchBox };
