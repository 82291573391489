// Sample service to make network call

import { API, NetworkManager } from "./core";

export class DepartmentService {
  static async addDepartment(payload) {
    const instance = new NetworkManager(API.DEPARTMENT.ADD, payload);
    return await instance.httpRequest(true);
  }

  static async updateDepartment(payload, params) {
    const instance = new NetworkManager(API.DEPARTMENT.UPDATE, payload, params);
    return await instance.httpRequest(true);
  }

  static async deleteDepartment(params) {
    const instance = new NetworkManager(API.DEPARTMENT.DELETE, null, params);
    return await instance.httpRequest(true);
  }
}
