import React, { useState } from "react";
import styles from "./index.module.scss";
import { Formik, Field, Form } from "formik";
import {
  Button,
  Typography,
  Divider,
  Autocomplete,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllResourceAction } from "redux/resource-and-project/actions";
import { useSkillChoices, useToast } from "hooks";
import { ResourceService } from "network/resourceService";
import AddIcon from "@mui/icons-material/Add";

const CreateResource = (props) => {
  const initialValues = {
    companyId: "",
    resourceName: "",
    experiencelevel: "",
    departmentId: "",
    designationId: "",
    availabilityForDeploy: ""
  };
  const [open, setOpen] = useState(false);
  const { showSuccess, showError } = useToast();
  const dispatch = useDispatch();
  const [selectedExpertise, setSelectedExpertise] = useState([]);
  const { skillChoices } = useSkillChoices();
  const allDepartmentsData = useSelector(
    (state) => state.resourceAndProject.departmentsData
  );
  const allDesignationsData = useSelector(
    (state) => state.resourceAndProject.designationsData
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  const onSubmitHandler = async (formData) => {
    const data = {
      ...formData,
      companyId: "NFX" + formData.companyId,
      expertise: selectedExpertise,
      experiencelevel: parseFloat(formData.experiencelevel)
    };
    const response = await ResourceService.addResource(data);
    if (response?.code === 200) {
      showSuccess({ message: "Resource added successfully" });
      dispatch(getAllResourceAction());
      handleClose();
    } else {
      showError({ message: response?.message || "Something went wrong" });
    }
  };

  React.useEffect(() => {
    if (open) {
      setSelectedExpertise([]);
    }
  }, [open]);
  return (
    <div>
      <Button
        variant="contained"
        onClick={handleOpen}
        startIcon={<AddIcon />}
        size="large">
        {props?.buttonTitle}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle variant="h5">Create New Resource</DialogTitle>
        <Divider />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
          enableReinitialize>
          {(formik) => {
            return (
              <Form>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={styles.form_heading}>
                        Resource Company ID
                      </Typography>
                      <Field
                        as={TextField}
                        placeholder="Company ID"
                        fullWidth
                        valueData={formik.values.departmentId}
                        name="companyId"
                        size="small"
                        multiline={true}
                        spellCheck="false"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              NFX
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={styles.bottom_form_heading}>
                        Resource Name
                      </Typography>
                      <Field
                        as={TextField}
                        placeholder="Resource Name"
                        fullWidth
                        name="resourceName"
                        size="small"
                        multiline={true}
                        spellCheck="false"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={styles.bottom_form_heading}>
                        Experience Level (years)
                      </Typography>
                      <Field
                        as={TextField}
                        placeholder="Experience Level"
                        fullWidth
                        name="experiencelevel"
                        size="small"
                        type="number"
                        multiline={true}
                        spellCheck="false"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={styles.form_heading}>
                        Department
                      </Typography>
                      <Autocomplete
                        options={allDepartmentsData}
                        getOptionLabel={(option) => option.name}
                        value={allDepartmentsData.find(
                          (department) =>
                            department.uuid === formik.values.departmentId
                        )}
                        onChange={(event, value) =>
                          formik.setFieldValue("departmentId", value?.uuid)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="eg. John Doe"
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={styles.form_heading}>
                        Designation
                      </Typography>
                      <Autocomplete
                        className={styles.selectbox}
                        options={allDesignationsData}
                        getOptionLabel={(option) => option.name}
                        value={allDesignationsData.find(
                          (designation) =>
                            designation.uuid === formik.values.designationId
                        )}
                        onChange={(event, value) =>
                          formik.setFieldValue("designationId", value.uuid)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="eg. Software Engineer"
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        component="h6"
                        className={styles.form_heading}>
                        Expertise
                      </Typography>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        options={skillChoices}
                        getOptionLabel={(option) => option.name}
                        value={skillChoices.filter((skill) =>
                          selectedExpertise.includes(skill.uuid)
                        )}
                        onChange={(event, value) =>
                          setSelectedExpertise(value.map((skill) => skill.uuid))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="eg. Java"
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider light />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} variant="outlined" size="large">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" size="large">
                    Create Resource
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
};

export default CreateResource;
