import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Typography } from "@mui/material";
import styles from "./Breadcrumbs.module.scss";

function Breadcrumb({ pathRoute }) {
  
    return (
        <>
            <Stack spacing={2}>
                <Breadcrumbs separator={<NavigateNextIcon color="rgba(0, 0, 0, 0.23)" />} aria-label="breadcrumb">
                    {pathRoute.map((item, index) => {
                        let isLast = index === pathRoute.length - 1
                        return isLast ? (<Typography key={index} className={styles.link_text}>{item.name}</Typography>) :
                            (<Link className={styles.link_text} key={index} underline="hover" color="primary" href={item.path}>{item.name}</Link>)

                    })}
                </Breadcrumbs>
            </Stack>
        </>
    )
}

export default Breadcrumb;