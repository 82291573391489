import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDepartmentsAction } from "redux/resource-and-project/actions";

const useDepartmentChoices = () => {
  const dispatch = useDispatch();
  const { departmentsData: departmentChoices } = useSelector(
    (state) => state.resourceAndProject
  );

  const refetch = () => {
    dispatch(getAllDepartmentsAction());
  };

  React.useEffect(() => {
    refetch();
  }, []);

  return {
    departmentChoices,
    refetch
  };
};

export { useDepartmentChoices };
