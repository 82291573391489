import React from "react";
import styles from "./dashboard.module.scss";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getDepartmentStatsAction,
  getAllocationStatsAction,
  getDesignationStatsAction,
  getProjectStatsAction
} from "redux/charts/actions";
import {
  AllocationBarChartByDepartment,
  AllocationBarChartByDesignation,
  AllocationPieChart,
  BillableAllocationPieChart,
  ChartTitle
} from "components/charts";
// import PeopleIcon from "@mui/icons-material/People";
// import SourceIcon from "@mui/icons-material/Source";
import Heading from "components/common-component/Heading";
// import { StatCard } from "components/cards";
import VerticalCard from "components/listCard/VerticalCard";
function Dashboard() {
  const dipatch = useDispatch();
  const departmentStatsData = useSelector(
    (state) => state.charts.departmentStats
  );
  const allocationStatsData = useSelector(
    (state) => state.charts.allocationStats
  );
  const designationStatsData = useSelector(
    (state) => state.charts.designationStats
  );
  // const projectStatsData = useSelector((state) => state.charts.projectStats);

  React.useEffect(() => {
    dipatch(getDepartmentStatsAction());
    dipatch(getAllocationStatsAction());
    dipatch(getDesignationStatsAction());
    dipatch(getProjectStatsAction());
  }, []);

  return (
    <Box className={styles.outer_container}>
      <Heading mainHeading={"Dashboard"} />
      <Box className={styles.scrollable_container}>
        <Grid container spacing={2} display="flex" mt={4} sx={{marginLeft:"1.5rem"}}>
          {/* <Grid item sm={12} md={4} lg={3.6}>
            <StatCard
              href="/resources"
              title="Total Resources"
              value={departmentStatsData?.totalResourceUserCount}
              bgColor="#cbdcf7"
              color="#1976d2"
              icon={<PeopleIcon fontSize="large" />}
            />
          </Grid> */}
          {/* <Grid item sm={12} md={4} lg={3.6}>
            <StatCard
              href="/projects"
              title="Total Projects"
              value={projectStatsData?.projectCount}
              bgColor="#ffe0b3"
              color="#ff9800"
              icon={<SourceIcon fontSize="large" />}
            />
          </Grid> */}
          <Grid sm={12} md={12} item lg={11}>
           <VerticalCard/>
          </Grid>
        </Grid>
          <Grid container spacing={2} sx={{marginTop:"4rem"}}>
            <Grid item xs={7}>
            {departmentStatsData?.departmentStats?.length > 0 && (
                <>
                  <ChartTitle
                    textAlign="center"
                    marginBottom="3rem"
                    title="Department Wise Allocation Distribution"
                  />
                  <AllocationBarChartByDepartment stats={departmentStatsData} />
                </>
              )}
            </Grid>
               <Grid item xs={5}>
               {allocationStatsData && (
                <>
                  <ChartTitle
                    textAlign={{ md: "left", lg: "center" }}
                    title="Billable Allocation Distribution"
                  />
                  <BillableAllocationPieChart stats={allocationStatsData} />
                </>
              )}
               </Grid>
          </Grid>
          <Grid
           container spacing={2} sx={{marginTop:"3rem", marginBottom:"6rem"}}>
            <Grid item xs={7}>
              {designationStatsData?.designationStats?.length > 0 && (
                <>
                  <ChartTitle
                    textAlign="center"
                    marginBottom="3rem"
                    title="Designation Wise Allocation Distribution"
                  />
                  <AllocationBarChartByDesignation
                    stats={designationStatsData}
                  />
                </>
              )}
              </Grid>
              <Grid item xs={5}>
              {allocationStatsData && (
                <>
                  <ChartTitle
                    textAlign={{ md: "left", lg: "center" }}
                    title="Resource Allocation Distribution"
                  />
                  <AllocationPieChart stats={allocationStatsData} />
                </>
              )}
            </Grid>
          </Grid>
      </Box>
    </Box>
  );
}

export default Dashboard;
