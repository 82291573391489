import React from "react";
import { Formik, Field, Form } from "formik";
import {
  Button,
  Typography,
  Divider,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Box
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const convertToFormikFormat = (data) => {
  return {
    uuid: data?.uuid || "",
    name: data?.name || "",
    projectType: data?.projectType || "Fixed",
    startDate: data?.startDate || new Date(),
    endDate: data?.endDate || new Date()
  };
};

const UpdateProjectModal = ({ open, onClose, project, onUpdate }) => {
  if (!project) {
    return null;
  }

  const formData = React.useMemo(
    () => convertToFormikFormat(project),
    [project]
  );
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    onClose && onClose();
  };

  const onSubmitHandler = async (formData) => {
    const data = {
      ...formData
    };
    onUpdate && onUpdate(data);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle variant="h5">Update Project</DialogTitle>
      <Divider />
      <Formik
        initialValues={formData}
        onSubmit={onSubmitHandler}
        enableReinitialize>
        {(formik) => {
          return (
            <Form>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                      Name of new Project
                    </Typography>
                    <Field
                      as={TextField}
                      placeholder="Name"
                      fullWidth
                      valueData={formik.values.departmentId}
                      name="name"
                      size="small"
                      multiline={true}
                      spellCheck="false"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                      Project Type
                    </Typography>
                    <Box>
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formik.values.projectType === "Fixed"}
                              onChange={() => {
                                formik.setFieldValue("projectType", "Fixed");
                              }}
                              name="fixed"
                              color="primary"
                            />
                          }
                          label="Fixed"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formik.values.projectType === "Retainer"}
                              onChange={() => {
                                formik.setFieldValue("projectType", "Retainer");
                              }}
                              name="retainer"
                              color="primary"
                            />
                          }
                          label="Retainer"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formik.values.projectType === "Hourly"}
                              onChange={() => {
                                formik.setFieldValue("projectType", "Hourly");
                              }}
                              name="hourly"
                              color="primary"
                            />
                          }
                          label="Hourly"
                        />
                      </Grid>
                    </Box>
                  </Grid>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        label="Start Date"
                        inputFormat="yyyy-MM-dd"
                        views={["year", "month", "day"]}
                        openTo="year"
                        maxDate={formik.values.endDate}
                        value={formik.values.startDate}
                        onChange={(date) =>
                          formik.setFieldValue("startDate", date)
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        label="End Date"
                        inputFormat="yyyy-MM-dd"
                        minDate={formik.values.startDate}
                        views={["year", "month", "day"]}
                        value={formik.values.endDate || null}
                        onChange={(date) =>
                          formik.setFieldValue("endDate", date)
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                  </LocalizationProvider>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="outlined" size="large">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" size="large">
                  Update
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export { UpdateProjectModal };
