import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import {
  Button,
  Typography,
  Box,
  Divider,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  addNewProjectAction,
  getAllProjectsAction
} from "redux/resource-and-project/actions";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const CreateProject = (props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    projectType: "Fixed",
    startDate: new Date(),
    endDate: ""
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitHandler = (data) => {
    dispatch(addNewProjectAction(data));
    // delay for 3 seconds
    setTimeout(() => {
      dispatch(getAllProjectsAction());
    }, 3000);

    handleClose();
  };

  return (
    <div>
      <Button
        size="large"
        startIcon={props?.icon || <AddIcon />}
        variant="contained"
        onClick={handleOpen}>
        {props?.buttonTitle}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle variant="h5">Add New Project</DialogTitle>
        <Divider />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
          enableReinitialize>
          {(formik) => {
            return (
              <Form>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={{marginBottom:"1rem"}} variant="h6" component="h6">
                        Name of new Project
                      </Typography>
                      <Field
                        as={TextField}
                        placeholder="Name"
                        fullWidth
                        valueData={formik.values.departmentId}
                        name="name"
                        size="small"
                        multiline={true}
                        spellCheck="false"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" component="h6">
                        Project Type
                      </Typography>
                      <Box>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formik.values.projectType === "Fixed"}
                                onChange={() => {
                                  formik.setFieldValue("projectType", "Fixed");
                                }}
                                name="fixed"
                                color="primary"
                              />
                            }
                            label="Fixed"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formik.values.projectType === "Retainer"
                                }
                                onChange={() => {
                                  formik.setFieldValue(
                                    "projectType",
                                    "Retainer"
                                  );
                                }}
                                name="retainer"
                                color="primary"
                              />
                            }
                            label="Retainer"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formik.values.projectType === "Hourly"}
                                onChange={() => {
                                  formik.setFieldValue("projectType", "Hourly");
                                }}
                                name="hourly"
                                color="primary"
                              />
                            }
                            label="Hourly"
                          />
                        </Grid>
                      </Box>
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid item xs={12} sm={6}>
                        <DatePicker
                          label="Start Date"
                          inputFormat="yyyy-MM-dd"
                          views={["year", "month", "day"]}
                          openTo="year"
                          maxDate={formik.values.endDate}
                          value={formik.values.startDate}
                          onChange={(date) =>
                            formik.setFieldValue("startDate", date)
                          }
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePicker
                          label="End Date"
                          inputFormat="yyyy-MM-dd"
                          minDate={formik.values.startDate}
                          views={["year", "month", "day"]}
                          value={formik.values.endDate || null}
                          onChange={(date) =>
                            formik.setFieldValue("endDate", date)
                          }
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </Grid>
                    </LocalizationProvider>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} variant="outlined" size="large">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" size="large">
                    Save
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
};

export default CreateProject;
