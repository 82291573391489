import { useEffect, useState } from "react";

// custom hooks around few util functions
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export const useActivity = (initValue) => useState(initValue || false);

/**
 * Build supplied string by interpolating properties after delimiter ':' with the given parameters.
 *
 * @example
 * interpolate('resource/:resourceId/deactivate', {resourceId: 'a4gh-5hjk-6lkm...'})
 * => 'resource/a4gh-5hjk-6lkm.../deactivate'
 */
export const interpolate = (str, params) => {
  let formattedString = str;

  for (const [key, value] of Object.entries(params)) {
    const val = value || "";

    formattedString = formattedString.replace(
      new RegExp(`:${key}`, "gi"),
      val.toString()
    );
  }

  return formattedString;
};
