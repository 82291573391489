import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from "@mui/material";
import {
  getCSVFileAction,
  UploadCSVFileAction
} from "redux/resource-and-project/actions";
import { useDispatch } from "react-redux";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import resourceAllocationSlice from "redux/resource-and-project/slice";

const UploadCSVFile = ({ open, handleClose }) => {
  const [uploadCSVFile, setUploadCSVFile] = React.useState(null);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("file", uploadCSVFile);
    dispatch(UploadCSVFileAction(formData));
    handleClose();
  };

  const handleDOwnloadCSVFile = () => {
    dispatch(getCSVFileAction());
  };

  const onClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    resourceAllocationSlice.actions.setCSVFileData();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>
        <Typography variant="h4">Nickelfox Bulk Employees</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box textAlign="center">
          {uploadCSVFile ? (
            <>
              <Box>
                <Typography variant="h6">
                  {uploadCSVFile?.name} file is selected
                </Typography>
                <Button
                  onClick={() => document.getElementById("myid").click()}
                  sx={{ marginTop: "20px" }}>
                  <FileCopyIcon sx={{ fontSize: "50px" }} />
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box>
                <Typography variant="h6">
                  Mandatory format to follow: Ensure that the file have
                  CompanyID, ResourceName
                </Typography>
                <Button
                  onClick={handleDOwnloadCSVFile}
                  sx={{ marginTop: "20px" }}>
                  Click here to download CSV format
                </Button>
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        {/* <Box textAlign="center"> */}
        <Button
          variant="outlined"
          size="large"
          onClick={() => {
            setUploadCSVFile(null);
            handleClose();
          }}>
          Cancel
        </Button>
        {uploadCSVFile === null ? (
          <Button
            variant="contained"
            size="large"
            type="submit"
            onClick={() => document.getElementById("myid").click()}>
            Browse
          </Button>
        ) : (
          <Button
            variant="contained"
            size="large"
            type="submit"
            onClick={handleSubmit}>
            Upload File
          </Button>
        )}
        <input
          type="file"
          style={{ display: "none" }}
          id="myid"
          onChange={(e) => setUploadCSVFile(e.target.files[0])}
        />
        {/* </Box> */}
      </DialogActions>
    </Dialog>
  );
};

export { UploadCSVFile };
