import React from "react";
import { Box, Select, MenuItem } from "@mui/material";
import styles from "./PageLimit.module.scss";

function PageLimit({pageLimit,handleChangeLimit}) {
    return (
        <>
            <Box className={styles.pagination_page_container}>
                <Box>
                    Rows Per Page :
                </Box>
                <Box>
                    <Select
                        value={pageLimit}
                        inputProps={{ "aria-label": "Without label" }}
                        className={styles.select_container}
                        onChange={handleChangeLimit}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </Box>

            </Box>
        </>
    )
}

export default PageLimit;