import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  loginAction,
  forgotPasswordAction,
  resetPasswordAction,
  updateCountAction,
  userDetailsAction
} from "./actions";
import auth from "./slice";
import forgotPasswordSlice from "./forgotPasswordSlice";
import app from "../app/slice";

const baseUrl = `${process.env.REACT_APP_API_URL}/v1/auth`;

export function* login(action) {
  try {
    const data = action.payload;
    const token = data.token;
    if (token) {
      localStorage.setItem("access_token", token);
      localStorage.setItem("refresh_token", token);
    }
    yield put(auth.actions.setUserData(data));
    yield put(app.actions.setIsLoggedInData(true));
  } catch (e) {
    e;
  }
}

export function* forgotPassword(action) {
  try {
    const {
      data: { data }
    } = yield call(axios.post, `${baseUrl}/forgotPassword`, action.payload);
    const response = {
      count: data.forgotLimit,
      email: data.email
    };

    yield put(forgotPasswordSlice.actions.setForgotPasswordData(response));
  } catch (e) {
    e;
  }
}

export function* resetPassword(action) {
  try {
    yield call(
      axios.post,
      `${baseUrl}/auth/resetPassword/${action.payload.token}`,
      action.payload.data
    );
  } catch (e) {
    e;
  }
}

export function* getUserDetails() {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/myProfile`);
    yield put(auth.actions.updateUserDetails(data?.data));
  } catch (e) {
    e;
  }
}

export function* updateCount(action) {
  yield put(forgotPasswordSlice.actions.setCount(action.payload));
}

export default function* users() {
  yield takeLatest(loginAction.type, login);
  yield takeLatest(forgotPasswordAction.type, forgotPassword);
  yield takeLatest(resetPasswordAction.type, resetPassword);
  yield takeLatest(updateCountAction.type, updateCount);
  yield takeLatest(userDetailsAction.type, getUserDetails);
}
