import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  getResourcesAllocationDataAction,
  getAllResourceAction,
  getAllProjectsAction,
  getAllDepartmentsAction,
  getAllDesignationAction,
  getAllExpertiseAction,
  addNewResourceAction,
  addNewDepartmentAction,
  addNewProjectAction,
  addNewSKillAction,
  AllocateResourceAction,
  updateAllocateResourceAction,
  removeAllocateResourceAction,
  addNewDesignationAction,
  getAllocationByFiltersAction,
  getCSVFileAction,
  UploadCSVFileAction,
  removeAllResourceAction,
  
} from "./actions";
import resourceSlice from "./slice";
import { toast } from "react-toastify";
import { ResourceService } from "network/resourceService";

const baseUrl = `${process.env.REACT_APP_API_URL}/v1`;

export function* getResourcesAndProjects() {
  try {
    const { data } = yield call(
      axios.get,
      `${baseUrl}/resourceAllocation/allDetails`
    );

    yield put(resourceSlice.actions.setResourceProjectData(data.data));
  } catch (e) {
    e;
  }
}

export function* getResources(action) {
  try {
    const { data } = yield call(ResourceService.getAllResource, action.payload);

    yield put(resourceSlice.actions.setResourceData(data));
  } catch (e) {
    e;
  }
}

export function* getProjects() {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/project`);

    yield put(resourceSlice.actions.setProjectData(data.data));
  } catch (e) {
    e;
  }
}

export function* getDepartments() {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/department`);

    yield put(resourceSlice.actions.setDepartmentsData(data.data));
  } catch (e) {
    e;
  }
}

export function* getDesignations() {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/designation`);

    yield put(resourceSlice.actions.setDesignationsData(data.data));
  } catch (e) {
    e;
  }
}

export function* getExpertise() {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/skill`);
    yield put(resourceSlice.actions.setExpertiseData(data.data));
  } catch (e) {
    e;
  }
}

export function* addNewResource(action) {
  try {
    const {
      data: { data }
    } = yield call(
      axios.post,
      `${baseUrl}/resource/addResource`,
      action.payload
    );

    toast.success(data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined
    });
  } catch (e) {
    e;
  }
}

export function* addNewDepartment(action) {
  try {
    const { data } = yield call(
      axios.post,
      `${baseUrl}/department/addDepartment`,
      action.payload
    );
    toast.success(data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined
    });
  } catch (e) {
    e;
  }
}

export function* addNewDesignation(action) {
  try {
    const { data } = yield call(
      axios.post,
      `${baseUrl}/designation/addDesignation`,
      action.payload
    );
    toast.success(data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined
    });
  } catch (e) {
    e;
  }
}

export function* addNewProject(action) {
  try {
    const { data } = yield call(
      axios.post,
      `${baseUrl}/project/addproject`,
      action.payload
    );
    toast.success(data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined
    });
  } catch (e) {
    e;
  }
}

export function* addNewExpertise(action) {
  try {
    const { data } = yield call(
      axios.post,
      `${baseUrl}/department/addDepartment`,
      action.payload
    );

    toast.success(data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined
    });
  } catch (e) {
    e;
  }
}

export function* allocateResource(action) {
  try {
    const { data } = yield call(
      axios.post,
      `${baseUrl}/resourceAllocation/add`,
      action.payload
    );

    toast.success(data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined
    });
  } catch (e) {
    e;
  }
}

export function* updateAllocateResource(action) {
  try {
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/resourceAllocation/allocationId/${action.payload.userId}`,
      action.payload.data
    );

    toast.success(data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined
    });
  } catch (e) {
    e;
  }
}

export function* removeAllocateResource(action) {
  try {
    const { data } = yield call(
      axios.delete,
      `${baseUrl}/resourceAllocation/allocationId/${action.payload}`
    );

    toast.success(data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined
    });
  } catch (e) {
    e;
  }
}

export function* removeAllResource(action) {
  try {
    const { data } = yield call(
      axios.delete,
      `${baseUrl}/resourceAllocation/deleteResource/${action.payload}`
    );

    toast.success(data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined
    });
  } catch (e) {
    e;
  }
}

export function* getAllocationByFilters(action) {
  try {
    const { data } = yield call(
      axios.get,
      `${baseUrl}/resourceAllocation/allDetails?${action.payload}`
    );
    yield put(resourceSlice.actions.setResourceProjectData(data.data));
  } catch (e) {
    e;
  }
}

export function* getCSVFile() {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/csv/download`);

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "resourceFormat.csv");
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    e;
  }
}

export function* uplodadCSVFile(action) {
  try {
    const { data } = yield call(
      axios.post,
      `${baseUrl}/csv/uploadFile`,
      action.payload
    );

    yield call(axios.post, `${baseUrl}/csv/manageAllocation`, {
      data: data?.data
    });
  } catch (e) {
    e;
  }
}

export default function* users() {
  // Get APIs
  yield takeLatest(
    getResourcesAllocationDataAction.type,
    getResourcesAndProjects
  );
  yield takeLatest(getAllResourceAction.type, getResources);
  yield takeLatest(getAllProjectsAction.type, getProjects);
  yield takeLatest(getAllDepartmentsAction.type, getDepartments);
  yield takeLatest(getAllDesignationAction.type, getDesignations);
  yield takeLatest(getAllExpertiseAction.type, getExpertise);
  yield takeLatest(getAllocationByFiltersAction.type, getAllocationByFilters);
  yield takeLatest(getCSVFileAction.type, getCSVFile);

  // Add APIs
  yield takeLatest(addNewResourceAction.type, addNewResource);
  yield takeLatest(addNewDepartmentAction.type, addNewDepartment);
  yield takeLatest(addNewSKillAction.type, addNewExpertise);
  yield takeLatest(addNewProjectAction.type, addNewProject);
  yield takeLatest(AllocateResourceAction.type, allocateResource);
  yield takeLatest(addNewDesignationAction.type, addNewDesignation);
  yield takeLatest(UploadCSVFileAction.type, uplodadCSVFile);

  // Update APIs
  yield takeLatest(updateAllocateResourceAction.type, updateAllocateResource);

  // Remove APIs
  yield takeLatest(removeAllocateResourceAction.type, removeAllocateResource);
  yield takeLatest(removeAllResourceAction.type, removeAllResource);

}
