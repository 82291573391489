import React, { useEffect, useState } from "react";
import styles from "./resources.module.scss";
import { Box, IconButton } from "@mui/material";
import ResourceListTable from "components/table";
import { getAllProjectsAction } from "redux/resource-and-project/actions";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteResourceModal } from "components/modal/DeleteResourceModal";
import { ProjectService } from "network/projectService";
import { useToast } from "hooks";
import { UpdateProjectModal } from "components/modal/";
import moment from "moment";
import TableLoader from "components/Loader/TableLoader";

const columns = [
  {
    id: "name",
    label: "Name",
    minWidth: 105
  },
  {
    id: "projectType",
    label: "Project Type",
    minWidth: 105
  },
  {
    id: "startDate",
    label: "Start Date",
    minWidth: 105
  },
  {
    id: "endDate",
    label: "End Date",
    minWidth: 105
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 105
  }
];

function createData(name, projectType, startDate, endDate, actions) {
  return {
    name,
    projectType,
    startDate,
    endDate,
    actions
  };
}

function ProjectTable() {
  const dispatch = useDispatch();
  const { showError, showSuccess } = useToast();

  const [updateProjectModalConfig, setUpdateProjectModalConfig] = useState({
    open: false,
    projectData: null
  });
  const [deleteProjectModalConfig, setDeleteProjectModalConfig] = useState({
    open: false,
    projectData: null
  });
  const [allProjectsListCount, setAllProjectsListCount] = useState(0);
  const [Isdataexist, setDataExist] = useState(true);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const dipatch = useDispatch();
  const allProjectsData = useSelector(
    (state) => state.resourceAndProject.projectData
  );
  
  const checkLoader = useSelector(
    (state) => state.app.loading
  );

  useEffect(() => {
    dipatch(getAllProjectsAction());
  }, []);

  useEffect(() => {
    if (allProjectsData.length > 0) {
      setDataExist(true);
      setAllProjectsListCount(allProjectsData.length);
    }
  }, [allProjectsData]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const handleEditClick = (idx) => {
    setUpdateProjectModalConfig({
      open: true,
      projectData: allProjectsData[idx]
    });
  };

  const handleDeleteClick = (idx) => {
    setDeleteProjectModalConfig({
      open: true,
      projectData: allProjectsData[idx]
    });
  };

  const allProjectsList = React.useMemo(() => {
    if (!allProjectsData || allProjectsData?.length === 0) {
      return [];
    }
    return allProjectsData?.map((list, idx) => {
      return createData(
        <Box key={idx} className={styles.ticketId_text}>
          {list?.name}
        </Box>,
        <Box key={idx} className={styles.ticketId_text}>
          {list?.projectType}
        </Box>,
        <Box key={idx} className={styles.ticketId_text}>
          {list?.startDate ? moment(list?.startDate).format('DD-MM-YYYY') : ""}
        </Box>,
        <Box key={idx} className={styles.ticketId_text}>
          {list?.endDate ? moment(list?.endDate).format('DD-MM-YYYY') : ""}
        </Box>,
        <Box key={idx} className={styles.ticketId_text} display="flex">
          <IconButton
            aria-label="edit"
            size="small"
            sx={{ mr: 0.5 }}
            onClick={() => handleEditClick(idx)}>
            <EditIcon />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="delete"
            size="small"
            sx={{ mr: 0.5 }}
            onClick={() => handleDeleteClick(idx)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      );
    });
  }, [allProjectsData]);

  const handleDeleteProject = React.useCallback(async () => {
    const response = await ProjectService.deleteProject([deleteProjectModalConfig.projectData.uuid]);
    if (response?.code === 200) {
      showSuccess({ message: response?.message || "Project Deleted successfully" });
      dispatch(getAllProjectsAction());
      setDeleteProjectModalConfig({
        open: false,
        projectData: null
      });
    } else {
      showError({ message: response?.data?.message || "Something went wrong" });
    }
  }, [deleteProjectModalConfig.projectData]);

  const handleDeleteProjectClose = () => {
    setDeleteProjectModalConfig({
      open: false,
      projectData: null
    });
  };

  const handleUpdateProject = React.useCallback(
    async (projectData) => {
      const response = await ProjectService.updateProject(projectData, [updateProjectModalConfig.projectData.uuid]);
      if (response?.code === 200) {
        showSuccess({ message: response?.message || "Project updated successfully" });
        dispatch(getAllProjectsAction());
        setUpdateProjectModalConfig({
          open: false,
          projectData: null
        });
      } else {
        showError({
          message: response?.data?.message || "Something went wrong"
        });
      }
    },
    [updateProjectModalConfig.projectData]
  );

  const handleUpdateProjectClose = () => {
    setUpdateProjectModalConfig({
      open: false,
      projectData: null
    });
  };

  return (
    <>
      <Box className={styles.box_container}>
        <Box className={styles.employee_list_search_container}>
          <Box className={styles.sub_container}>
            <Box className={styles.search_box}>
              {false && (
                <Box className={styles.employees_text}>
                  {allProjectsListCount} Project
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {/* For mobile responsive */}
        <Box
          className={styles.mobile_search_box}
          sx={{ display: { xs: "block", sm: "none" } }}></Box>
        <Box
          className={styles.table_container}
          sx={{
            maxWidth: `${windowSize.innerWidth - 35}px`
          }}>
          {checkLoader ? <TableLoader /> :
            <ResourceListTable
              route="resources"
              createData={createData}
              columns={columns}
              tableData={allProjectsList}
              Isdataexist={Isdataexist}
            />
          }
          <UpdateProjectModal
            open={updateProjectModalConfig.open}
            project={updateProjectModalConfig.projectData}
            onClose={handleUpdateProjectClose}
            onUpdate={handleUpdateProject}
          />
          <DeleteResourceModal
            content={`Are you sure you want to delete ${deleteProjectModalConfig.projectData?.name ?? ""
              }?`}
            title="Delete Project"  
            open={deleteProjectModalConfig.open}
            onClose={handleDeleteProjectClose}
            onDelete={handleDeleteProject}
          />
        </Box>
      </Box>
    </>
  );
}

export default ProjectTable;
