import React from "react";
import { toast } from "react-toastify";

const Container = (props) => <div>{props.children}</div>;

const getMessageTemplate = (message) => {
  return <Container>{message}</Container>;
};

export const useToast = () => {
  const showError = ({ message }) => {
    toast(getMessageTemplate(message, "error"), {
      type: "error",
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      toastId: "error"
    });
  };

  const showSuccess = ({ message }) => {
    toast(getMessageTemplate(message, "success"), {
      type: "success",
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      toastId: "success"
    });
  };

  const showInfo = ({ message }) => {
    toast(getMessageTemplate(message, "info"), {
      type: "info",
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      toastId: "info"
    });
  };
  return {
    showError,
    showSuccess,
    showInfo
  };
};
