import React from "react";
import {
  Box,
  Divider,
  CardMedia,
  OutlinedInput,
  Button,
  Typography,
  // Select,
  Autocomplete,
  TextField
} from "@mui/material";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
// import UserImg from "assets/images/backgrounds/healthicons_ui-user-profile-outline (1).png";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { IconButton } from "@mui/material";
import styles from "./ProfileEdit.module.scss";
import Avatar from "assets/images/icons/profile.png";
import { useDepartmentChoices, useToast } from "hooks";
import { useHistory } from "react-router-dom/";
import { AuthService } from "network/authService";
import { useUserDetails } from "hooks";
function ProfileEdit() {
  const { userDetails: user, refetch: refetchUserDetails } = useUserDetails();
  const { departmentChoices } = useDepartmentChoices();
  const history = useHistory();
  const { showSuccess, showError } = useToast();
  let initialValues = {
    name: user?.name ? user?.name : "",
    department: user?.department ? user?.department : ""
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .trim(),
    department: Yup.string().notRequired().trim()
  });

  function handleCancel() {
    history.push("/profile");
  }
  async function handlesubmit(data) {
    let body = {
      name: data.name,
      email: user.email,
      department: data.departmentName
    };
    let updateUserDetail = await AuthService.updateUser(body);
    if (updateUserDetail.code === 200 && updateUserDetail.success === true) {
      showSuccess({ message: "Profile updated successfully" });
      refetchUserDetails();
      history.push("/profile");
    } else {
      showError({
        message: updateUserDetail?.message || "Something went wrong"
      });
    }
  }
  return (
    <>
      <Box className={styles.box_container}>
        <Box className={styles.edit_text_container}>Edit Details</Box>
        <Divider light />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handlesubmit}>
          {(formik) => {
            if (formik.errors.file) {
              // setImgData(null);
            }
            return (
              <Form onSubmit={formik.handleSubmit}>
                <Box className={styles.img_error_container}>
                  {formik.errors.file ? (
                    <div className={styles.img_error_message}>
                      {formik.errors.file}
                    </div>
                  ) : (
                    ""
                  )}
                </Box>
                <Box className={styles.edit_box_container}>
                  <Box className={styles.img_container}>
                    <Box className={styles.img_inner_container}>
                      <CardMedia
                        className={styles.userimg}
                        component="img"
                        image={Avatar}
                        alt="profile"
                        height="130px"
                        width="130px"
                      />

                      <Box className={styles.edit_icon_container}>
                        <IconButton
                          component="label"
                          className={styles.upload_button}
                          // onChange={onChangePicture}
                        >
                          <ModeEditOutlineOutlinedIcon
                            className={styles.img_icon}
                          />
                          <input
                            type="file"
                            accept="image/*"
                            hidden
                            name="file"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "file",
                                e.currentTarget.files[0]
                              );
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={styles.edit_container}>
                    <Box className={styles.text_container}>
                      <Box className={styles.inner_text_container}>
                        <Box>
                          <Box className={styles.heading_container}>Name</Box>
                          <Box className={styles.text_field_container}>
                            <Field
                              as={OutlinedInput}
                              className={styles.text_field}
                              type="text"
                              // onChange={formik.handleChange}
                              name="name"
                              spellCheck="false"
                            />
                          </Box>
                          <Box className={styles.error_container}>
                            {formik.errors.name && formik.touched.name ? (
                              <Typography
                                variant="h6"
                                component="h6"
                                className={styles.error_message}>
                                {formik.errors.name}
                              </Typography>
                            ) : null}
                          </Box>
                        </Box>
                      </Box>
                      <Box className={styles.inner_text_container}>
                        <Box className={styles.heading_container}>
                          Department
                        </Box>
                        <Box className={styles.text_field_container}>
                          <Autocomplete
                            options={departmentChoices}
                            getOptionLabel={(option) => option.name}
                            value={departmentChoices.find(
                              (department) =>
                                department.uuid === formik.values.departmentId
                            )}
                            onChange={(event, value) => {
                              formik.setFieldValue(
                                "departmentName",
                                value?.name
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={formik.values.department}
                                color="secondary"
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className={styles.button_container}>
                      <Box className={styles.cancel_btn}>
                        <Button onClick={handleCancel} variant="">
                          Cancel
                        </Button>
                      </Box>
                      <Box className={styles.save_btn}>
                        <Button variant="" type="submit">
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
}

export default ProfileEdit;
