import React, { useState } from "react";
import { IconButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import Popover from "@mui/material/Popover";
import { Box } from "@mui/material";
import FilterComponent from "./FilterComponent";

function Filter(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        size="small"
        disableRipple
        onClick={handleClick}
        sx={{ position: "relative" }}>
        <FilterListIcon size="medium" color="secondary" />
      </IconButton>

      <Box>
        {" "}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          sx={{ top: "1.5rem" }}>
          <FilterComponent {...props} onClose={handleClose} />
        </Popover>
      </Box>
    </>
  );
}

export default Filter;
