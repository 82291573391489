import _ from "lodash";
import React, { useEffect, useState } from "react";
import styles from "./resourceList.module.scss";
import Heading from "components/common-component/Heading";
import { Box, Button, Chip, Pagination, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ResourceListTable from "components/table";
import { useDispatch, useSelector } from "react-redux";
import {
  getResourcesAllocationDataAction,
  getAllResourceAction,
  getAllProjectsAction,
  getAllExpertiseAction,
  updateAllocateResourceAction,
  removeAllocateResourceAction,
  getAllocationByFiltersAction,
} from "redux/resource-and-project/actions";
import {
  AllocationFilter,
  CreateAllocationModal,
  parseFilter,
  ProjectsModal
} from "components/resourceAllocation";
import { capitaliseNameInitials } from "components/utilityFunction/UtilityFunction";
import { useHistory, useLocation } from "react-router-dom";
import PageLimit from "components/common-component/PageLimit";
import { AllocationService } from "network/allocationService";
import { UploadCSVFile } from "components/modal/UploadCSVFile";
import { useToast } from "hooks";
import NoDataFound from "components/common-component/noDataFound/NoDataFound";
import { SearchBox } from "components/common-component";
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import TableLoader from "components/Loader/TableLoader";

function createData(
  companyId,
  resourceName,
  department,
  designation,
  expertise,
  experiencelevel,
  project,
  projectTech,
  billable,
  isBillable,
  allocated,
  availability,
  startedWorkingDate,
  endWorkingDate
) {
  return {
    companyId,
    resourceName,
    department,
    designation,
    expertise,
    experiencelevel,
    project,
    projectTech,
    billable,
    isBillable,
    allocated,
    availability,
    startedWorkingDate,
    endWorkingDate
  };
}

function ResourceList() {
  const history = useHistory();
  const { search } = useLocation();
  const { showError, showSuccess } = useToast();
  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [initialValuesForFilters, setInitialValuesForFilters] = useState({
    search: "",
    department: [],
    resourceUser: [],
    project: [],
    designation: [],
    expertise: [],
    billable: null,
    sortKey: "",
    sortValue: ""
  });
  const [Isdataexist, setDataExist] = useState(true);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [projectsModalConfig, setProjectModalConfig] = useState({
    isOpen: false,
    data: null
  });
  const [createAllocationModalConfig, setCreateAllocationModalConfig] =
    useState({
      isOpen: false,
      data: null
    });
  const [uploadCSVModalConfig, setUploadCSVModalConfig] = useState({
    isOpen: false,
    data: null
  });
  const { resourceAllocation: allResourcesAndProjectsData } = useSelector(
    (state) => state.resourceAndProject.projectResourceData
  );
  
  const checkLoader = useSelector(
    (state) => state.app.loading
  );

  const [resourcesSortIcon, setResourcesSortIcon] = useState(true);
  const [expSortIcon, setExpSortIcon] = useState(true);

  const handleResourceSorting = () => {
    setResourcesSortIcon(!resourcesSortIcon);
    handleSorting("resourceName", resourcesSortIcon ? "0" : "1");
    setExpSortIcon(true);
  }

  const handleExpSorting = () => {
    setExpSortIcon(!expSortIcon);
    handleSorting("experiencelevel", expSortIcon ? "0" : "1");
    setResourcesSortIcon(true);
  }

  const handleSorting = (sortKey, sortValue) => {

    const newFilters = { ...initialValuesForFilters, sortKey, sortValue };
    setInitialValuesForFilters(newFilters);
    const filtersQuery = parseFilter(_.cloneDeep(newFilters));
    dispatch(getAllocationByFiltersAction(filtersQuery));
  }
  const dispatch = useDispatch();

  const columns = [
    {
      id: "companyId",
      label: "Company ID",
      minWidth: 140
    },
    {
      id: "resourceName",
      label: <Box className={styles.tabel_header}>
        <IconButton onClick={handleResourceSorting} >
          {resourcesSortIcon ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
        </IconButton>
        <Box>
          Resource Name
        </Box>
      </Box>,
      minWidth: 140
    },
    { id: "department", label: "Department", minWidth: 140 },
    {
      id: "designation",
      label: "Designation",
      minWidth: 140
    },
    {
      id: "expertise",
      label: "Resource Expertise",
      minWidth: 175
    },
    {
      id: "experiencelevel",
      label: <Box className={styles.tabel_header}>
        <IconButton onClick={handleExpSorting} >
          {expSortIcon ? <ArrowUpwardOutlinedIcon /> : <ArrowDownwardOutlinedIcon />}
        </IconButton>
        <Box>
          Experience Level (years)
        </Box>
      </Box>,
      minWidth: 140
    },
    {
      id: "project",
      label: "Project",
      minWidth: 140
    },
    {
      id: "projectTech",
      label: "Project Technologies",
      minWidth: 160
    },
    {
      id: "isBillable",
      label: "Billable/Non Billable",
      minWidth: 160
    },
    {
      id: "billable",
      label: "Billable (%)",
      minWidth: 140
    },
    {
      id: "allocated",
      label: "Allocated (%)",
      minWidth: 140
    },
    {
      id: "availability",
      label: "Availability (%)",
      minWidth: 140
    },
    {
      id: "startedWorkingDate",
      label: "Started Working From",
      minWidth: 160
    },
    {
      id: "endWorkingDate",
      label: "End Working Date",
      minWidth: 140
    }
  ];

  const refetchData = () => {
    dispatch(getResourcesAllocationDataAction());
  };

  useEffect(() => {
    // if add in search then open create allocation
    if (search.includes("add")) {
      setCreateAllocationModalConfig({
        ...createAllocationModalConfig,
        isOpen: true
      });
    }
  }, [search]);

  useEffect(() => {
    dispatch(getAllResourceAction());
    dispatch(getAllProjectsAction());
    dispatch(getAllExpertiseAction());
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (allResourcesAndProjectsData?.length > 0) {
      setDataExist(true);
    }
  }, [allResourcesAndProjectsData]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function getFullDate(value) {
    if (!value) return;
    return `${new Date(value).getFullYear()}/${new Date(
      value
    ).getMonth()}/${new Date(value).getDate()}`;
  }

  function handleChangeLimit(e) {
    setPageLimit(e.target.value);
  }

  function handleChangePage(event, value) {
    setPage(value);
  }

  const handleClickOpenProjectsModal = (resourceAllocation) => {
    setProjectModalConfig({
      ...projectsModalConfig,
      isOpen: true,
      data: {
        resourceAllocation
      }
    });
  };

  const handleProjectsModalClose = () => {
    setProjectModalConfig({
      ...projectsModalConfig,
      isOpen: false,
      data: null
    });
  };

  const handleClickOpenCreateAllocationModal = () => {
    setCreateAllocationModalConfig({
      ...createAllocationModalConfig,
      isOpen: true
    });
  };

  const handleUploadCSVModal = () => {
    setUploadCSVModalConfig({
      ...uploadCSVModalConfig,
      isOpen: true
    });
  };

  const handleUploadCSVModalClose = () => {
    setUploadCSVModalConfig({
      ...uploadCSVModalConfig,
      isOpen: false
    });
  };

  const handleCreateAllocationModalClose = () => {
    setCreateAllocationModalConfig({
      ...createAllocationModalConfig,
      isOpen: false
    });
  };

  const handleCreateAllocationModalSave = async (data) => {
    const response = await AllocationService.addAllocation(data);
    if (response?.code === 200) {
      showSuccess({ message: "Resource allocated successfully" });
      refetchData();
      handleCreateAllocationModalClose();
      // remove query param
      history.replace("/allocation");
    } else {
      showError({ message: response?.message || "Something went wrong" });
    }
  };

  const handleSubmit = async (data, id) => {
    dispatch(updateAllocateResourceAction({ data, userId: id }));
    // delay for 2 seconds
    setTimeout(() => {
      refetchData();
    }, 2000);
  };

  const handleRemoveAllocation = async (id) => {
    dispatch(removeAllocateResourceAction(id));
    // delay for 2 seconds
    setTimeout(() => {
      refetchData();
    }, 2000);
  };

  // eslint-disable-next-line no-unused-vars
  const handleFiltersChange = (appliedFilter, filterQuery) => {
    setInitialValuesForFilters(appliedFilter);
    dispatch(getAllocationByFiltersAction(filterQuery));
  };

  const handleSearch = React.useCallback(
    (search) => {
      const newFilters = { ...initialValuesForFilters, search };
      setInitialValuesForFilters(newFilters);
      const filtersQuery = parseFilter(_.cloneDeep(newFilters));
      dispatch(getAllocationByFiltersAction(filtersQuery));
      
    },
    [initialValuesForFilters, setInitialValuesForFilters]
  );

  const allResourcesList = React.useMemo(() => {
    if (
      !allResourcesAndProjectsData ||
      allResourcesAndProjectsData?.length === 0
    ) {
      return [];
    }
    return allResourcesAndProjectsData.map((list) => {
      const hasSingleProject = list?.ResourceProjectDetails?.length === 1;
      let experience =
        list?.experiencelevel && parseFloat(list?.experiencelevel).toFixed(1);
      if (list?.experiencelevel && list?.experiencelevel?.includes(".0")) {
        experience = parseInt(list?.experiencelevel);
      }
      return createData(
        <Box key={list.resourceUserId} className={styles.ticketId_name}>
          {list?.companyId}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {capitaliseNameInitials(list?.resourceName)}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {capitaliseNameInitials(list?.Departement?.name)}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {capitaliseNameInitials(list?.Designation?.name)}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {list?.Expertise?.map((item, idx) => (
            <Chip key={idx} label={item.name} sx={{ m: 0.5 }} />
          ))}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {experience ?? "No Experience"}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {capitaliseNameInitials(
            list?.ResourceProjectDetails?.[0]?.Project?.name
          ) || "No Allocations"}
          {list?.ResourceProjectDetails?.length > 1 ? (
            <Button
              sx={{
                fontSize: "14px",
                ":hover": {
                  backgroundColor: "transparent",
                  color: "secondary.main"
                }
              }}
              onClick={(e) => {
                console.log("list", list);
                e.stopPropagation();
                handleClickOpenProjectsModal(list);
              }}>
              {`+${list?.ResourceProjectDetails?.length - 1} more`}
            </Button>
          ) : (
            ""
          )}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {hasSingleProject &&
            list?.ResourceProjectDetails?.[0]?.projectTech?.map((name, idx) => (
              <Chip key={idx} label={name} sx={{ m: 0.5 }} />
            ))}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {hasSingleProject &&
            `${list?.ResourceProjectDetails?.[0]?.billablePercent || 0}%`}
        </Box>,
        <Box
          display="flex"
          key={list.resourceUserId}
          className={styles.ticketId_text}
          sx={{
            color: "success.main"
          }}>
          {hasSingleProject &&
            (list?.ResourceProjectDetails?.[0]?.billable
              ? "Billable"
              : "Non Billable")}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {hasSingleProject &&
            `${list?.ResourceProjectDetails?.[0]?.allocatedPercent || 0}%`}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {list?.ResourceProjectDetails?.[0]?.availabilityForDeploy ? `${list?.ResourceProjectDetails?.[0]?.availabilityForDeploy}%` : "..."}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {hasSingleProject &&
            getFullDate(list?.ResourceProjectDetails?.[0]?.startedWorkingDate)}
        </Box>,
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {hasSingleProject &&
            getFullDate(list?.ResourceProjectDetails?.[0]?.endWorkingDate)}
        </Box>
      );
    });
  }, [allResourcesAndProjectsData]);

  return (
    <>
      <Box className={styles.outer_container}>
        <Heading mainHeading="Resource Allocation" />
        <Box className={styles.employee_list_wrapper}>
          <Box className={styles.box_container}>
            <Box className={styles.employee_list_search_container}>
              <Box className={styles.sub_container}>
                <Box className={styles.search_box}>
                  <Box className={styles.employees_text}>
                    {allResourcesAndProjectsData?.length}{" "}
                    {allResourcesAndProjectsData?.length === 1
                      ? "Allocation"
                      : "Allocations"}
                  </Box>
                  <Box className={styles.issue_button_container}>
                    <Box display="flex" alignItems="center" marginRight={5}>
                      <SearchBox placeholder="Search" onChange={handleSearch} />
                    </Box>
                    <Box marginRight={5}>
                      <AllocationFilter
                        filter={initialValuesForFilters}
                        onAppplyFilter={handleFiltersChange}
                      />
                    </Box>
                    <Box marginRight={5}>
                      <Button
                        startIcon={<AddIcon />}
                        variant="contained"
                        size="large"
                        onClick={handleUploadCSVModal}>
                        Upload CSV File
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        startIcon={<AddIcon />}
                        variant="contained"
                        size="large"
                        onClick={handleClickOpenCreateAllocationModal}>
                        Allocate Resource
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <UploadCSVFile
              {...uploadCSVModalConfig?.data}
              open={uploadCSVModalConfig?.isOpen}
              handleClose={handleUploadCSVModalClose}
            />
            <CreateAllocationModal
              {...createAllocationModalConfig?.data}
              open={createAllocationModalConfig?.isOpen}
              handleClose={handleCreateAllocationModalClose}
              handleSave={handleCreateAllocationModalSave}
            />
            <ProjectsModal
              {...projectsModalConfig?.data}
              open={projectsModalConfig?.isOpen}
              handleClose={handleProjectsModalClose}
              onSubmit={handleSubmit}
              onRemoveAllocation={handleRemoveAllocation}
            />
            {/* For mobile responsive */}
            <Box
              className={styles.mobile_search_box}
              sx={{ display: { xs: "block", sm: "none" } }}></Box>
            <Box
              className={styles.table_container}
              sx={{
                maxWidth: { md: `${windowSize.innerWidth - 35}px`, lg: `${windowSize.innerWidth - 330}px` }
              }}>
              {allResourcesAndProjectsData?.length > 0 ? (
                checkLoader ? <TableLoader /> :
                  <ResourceListTable
                    updateRoute="allocation"
                    createData={createData}
                    addStyle={true}
                    columns={columns}
                    tableData={allResourcesList}
                    Isdataexist={Isdataexist}
                  />
              ) : (
                <NoDataFound errorMsg="No Data Found" />
              )}
            </Box>
          </Box>
        </Box>
        <Box className={styles.pagination_container}>
          {allResourcesAndProjectsData?.length > 0 ? (
            <>
              <PageLimit
                pageLimit={pageLimit}
                handleChangeLimit={handleChangeLimit}
              />
              <Pagination
                count={Math.ceil(
                  parseFloat(allResourcesAndProjectsData?.length / pageLimit)
                )}
                page={page}
                onChange={handleChangePage}
                hideNextButton
                hidePrevButton
                color="secondary"
              />{" "}
            </>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
}

export default ResourceList;
