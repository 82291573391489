import React from "react";
import { Box, Divider, CardMedia, IconButton } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import UserImg from "assets/images/icons/profile.png";
import styles from "./ProfileView.module.scss";
import { useHistory } from "react-router-dom";
import { userDetail } from "hooks/state";

function ProfileView() {
  const history = useHistory()
  const user = userDetail()
  function handleEdit(){
       history.push(`/profile/${user.uuid}`)
  }
  return (
  
    <>
      <Box className={styles.box_container}>
        <Box className={styles.name_container}>
            <Box className={styles.username_text}>
              {user.name}
            </Box>
          <Box className={styles.edit_container}>
            <Box className={styles.edit_icon}>
              <IconButton  onClick={handleEdit}>
                <ModeEditOutlineOutlinedIcon
                  size="small"
                  sx={{ height: "18px", width: "18px" }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box className={styles.divider_container}>
          <Divider light />
        </Box>
        <Box>
          <Box className={styles.inner_wrapper}>
            <Box className={styles.img_container}>
              <CardMedia
                className={styles.userimg}
                component="img"
                image={UserImg}
                alt="profile"
                name="file"
              />
            </Box>
            <Box className={styles.content_container}>
              <Box className={styles.firstContainer}>
                <Box className={styles.inner_container}>
                  <Box className={styles.heading_text}>Name</Box>
                  <Box className={styles.name_text}>
                    {user?.name ? user?.name : "..."}
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box className={styles.inner_container}>
                  <Box className={styles.heading_text}>Email Id</Box>
                  <Box className={styles.name_text}>
                    {user?.email ? user?.email : "..."}
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box className={styles.inner_container}>
                  <Box className={styles.heading_text}>Department</Box>
                  <Box className={styles.name_text}>
                    {user?.department ? user?.department : "..." }
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ProfileView;
