// Sample service to make network call

import { API, NetworkManager } from "./core";

export class ProjectService {
  static async addProject(payload) {
    const instance = new NetworkManager(API.PROJECT.ADD, payload);
    return await instance.httpRequest(true);
  }

  static async updateProject(payload, params) {
    const instance = new NetworkManager(API.PROJECT.UPDATE, payload, params);
    return await instance.httpRequest(true);
  }

  static async deleteProject(params) {
    const instance = new NetworkManager(API.PROJECT.DELETE, null, params);
    return await instance.httpRequest(true);
  }
}
