import React, { useEffect, useState } from "react";
import styles from "./resources.module.scss";
import { Box, IconButton } from "@mui/material";
import ResourceListTable from "components/table";
import { getAllDepartmentsAction } from "redux/resource-and-project/actions";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useToast } from "hooks";
import { DepartmentService } from "network/departmentService";
import { DeleteResourceModal } from "components/modal/DeleteResourceModal";
import { UpdateDepartmentModal } from "components/modal";
import TableLoader from "components/Loader/TableLoader";

const columns = [
  {
    id: "name",
    label: "Name",
    minWidth: 105
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 105
  }
];

function createData(name, actions) {
  return {
    name,
    actions
  };
}

function DepartmentTable() {
  const dispatch = useDispatch();
  const { showError, showSuccess } = useToast();

  const [updateDepartmentModalConfig, setUpdateDepartmentModalConfig] =
    useState({
      open: false,
      departmentData: null
    });
  const [deleteDepartmentModalConfig, setDeleteDepartmentModalConfig] =
    useState({
      open: false,
      departmentData: null
    });
  const [allDepartmentsListCount, setAllDepartmentsListCount] = useState(0);
  const [Isdataexist, setDataExist] = useState(true);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const dipatch = useDispatch();
  const allDepartmentsData = useSelector(
    (state) => state.resourceAndProject.departmentsData
  );

  const checkLoader = useSelector(
    (state) => state.app.loading
  );

  useEffect(() => {
    dipatch(getAllDepartmentsAction());
  }, []);

  useEffect(() => {
    if (allDepartmentsData.length > 0) {
      setDataExist(true);
      setAllDepartmentsListCount(allDepartmentsData.length);
    }
  }, [allDepartmentsData]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const handleEditClick = (idx) => {
    setUpdateDepartmentModalConfig({
      open: true,
      departmentData: allDepartmentsData[idx]
    });
  };

  const handleUpdateDepartment = React.useCallback(
    async (departmentData) => {
      const response = await DepartmentService.updateDepartment(
        departmentData,
        [updateDepartmentModalConfig.departmentData.uuid]
      );
      if (response?.code === 200) {
        showSuccess({ message:  response?.message || "Department updated successfully" });
        dispatch(getAllDepartmentsAction());
        setUpdateDepartmentModalConfig({
          open: false,
          departmentData: null
        });
      } else {
        showError({
          message: response?.data?.message || "Something went wrong"
        });
      }
    },
    [updateDepartmentModalConfig.departmentData]
  );

  const handleUpdateDepartmentClose = () => {
    setUpdateDepartmentModalConfig({
      open: false,
      departmentData: null
    });
  };

  const handleDeleteClick = (idx) => {
    setDeleteDepartmentModalConfig({
      open: true,
      departmentData: allDepartmentsData[idx]
    });
  };

  const handleDeleteDepartmentClose = () => {
    setDeleteDepartmentModalConfig({
      open: false,
      departmentData: null
    });
  };

  const handleDeleteDepartment = React.useCallback(async () => {
    const response = await DepartmentService.deleteDepartment([deleteDepartmentModalConfig.departmentData.uuid]);
    if (response?.code === 200) {
      showSuccess({ message: response?.message || "Department deleted successfully" });
      dispatch(getAllDepartmentsAction());
      setDeleteDepartmentModalConfig({
        open: false,
        departmentData: null
      });
    } else {
      showError({ message: response?.data?.message || "Something went wrong" });
    }
  }, [deleteDepartmentModalConfig.departmentData]);

  const allDepartmentsList = React.useMemo(() => {
    if (!allDepartmentsData || allDepartmentsData?.length === 0) {
      return [];
    }
    return allDepartmentsData?.map((list, idx) => {
      return createData(
        <Box key={list.resourceUserId} className={styles.ticketId_text}>
          {list?.name}
        </Box>,
        <Box key={idx} className={styles.ticketId_text} display="flex">
          <IconButton
            aria-label="edit"
            size="small"
            sx={{ mr: 0.5 }}
            onClick={() => handleEditClick(idx)}>
            <EditIcon />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="delete"
            size="small"
            sx={{ mr: 0.5 }}
            onClick={() => handleDeleteClick(idx)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      );
    });
  }, [allDepartmentsData]);

  return (
    <>
      <Box className={styles.box_container}>
        <Box className={styles.employee_list_search_container}>
          <Box className={styles.sub_container}>
            <Box className={styles.search_box}>
              {false && (
                <Box className={styles.employees_text}>
                  {allDepartmentsListCount} Department
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {/* For mobile responsive */}
        <Box
          className={styles.mobile_search_box}
          sx={{ display: { xs: "block", sm: "none" } }}></Box>
        <Box
          className={styles.table_container}
          sx={{
            maxWidth: `${windowSize.innerWidth - 35}px`
          }}>
          {checkLoader ? <TableLoader /> :
            <ResourceListTable
              route="resources"
              createData={createData}
              columns={columns}
              tableData={allDepartmentsList}
              Isdataexist={Isdataexist}
            />
          }
          <UpdateDepartmentModal
            open={updateDepartmentModalConfig.open}
            department={updateDepartmentModalConfig.departmentData}
            onClose={handleUpdateDepartmentClose}
            onUpdate={handleUpdateDepartment}
          />
          <DeleteResourceModal
            content={`Are you sure you want to delete ${
              deleteDepartmentModalConfig.departmentData?.name ?? ""
            }?`}
            title="Delete Department"  
            open={deleteDepartmentModalConfig.open}
            onClose={handleDeleteDepartmentClose}
            onDelete={handleDeleteDepartment}
          />
        </Box>
      </Box>
    </>
  );
}

export default DepartmentTable;
