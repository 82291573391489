import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const prepareData = (designationStats) => {
  const data = [];
  designationStats?.forEach((stat) => {
    // check if the both billable and non-billable count is zero
    if (
      stat.allocationDetails.billable.count == 0 &&
      stat.allocationDetails.nonBillable.count == 0
    ) {
      return;
    }
    data.push({
      name: stat.name,
      userCount: stat.resourceUserCount,
      billableCount: stat.allocationDetails.billable.count,
      nonBillableCount: stat.allocationDetails.nonBillable.count
    });
  });
  return data;
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const formatDesignationLabelsLong = (value, payload) => {
  if (value) {
    const words = value.split(" ");
    let result = "";
    if (words.length > 1) {
      if (words[0].includes("/")) {
        result =
          words[0].toUpperCase() +
          " " +
          words
            .slice(1)
            .map((word) => {
              return capitalize(word);
            })
            .join("");
      } else {
        result = words
          .map((word) => {
            return capitalize(word);
          })
          .join(" ");
      }
    } else {
      result = capitalize(value);
    }
    return `${result} ${payload ? `- ${payload?.userCount || 0}` : ""}`;
  }
};

const formatDesignationLabelsShort = (value) => {
  if (value) {
    const words = value.split(" ");
    if (words.length > 1) {
      return words
        .map((word) => {
          return `${word?.[0] || ""}`.toUpperCase();
        })
        .join("");
    } else {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
};

// eslint-disable-next-line no-unused-vars
const CustomizedTick = (props) => {
  const { x, y, payload } = props;
  // eslint-disable-next-line no-unused-vars
  const [label, setLabel] = React.useState(
    formatDesignationLabelsShort(payload.value)
  );
  // eslint-disable-next-line no-unused-vars
  const [hoverProps, setHoverProps] = React.useState({});
  const onMouseEnter = () => {
    return;
    // const result = formatDesignationLabelsLong(payload.value, null);
    // setLabel(result);
    // setHoverProps({
    //   fontSize: "12px",
    //   width: "30px",
    //   wordWrap: "break-word",
    //   wordBreak: "break-all",
    //   whiteSpace: "normal",
    //   height: "50px",
    //   lineHeight: "15px",
    //   texOverflow: "ellipsis",
    //   overflow: "hidden"
    // });
  };
  const onMouseLeave = () => {
    return;
    // setLabel(formatDesignationLabelsShort(payload.value));
    // setHoverProps({});
  };
  // bacckground color white and z-index 1
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        {...hoverProps}
        fontSize="12px"
        // style={{
        //   cursor: "pointer",
        //   backgroundColor: "white",
        //   zIndex: 100,
        //   padding: 2
        // }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#666">
        {label}
      </text>
    </g>
  );
};

export const AllocationBarChartByDesignation = ({ stats }) => {
  if (Object.keys(stats).length === 0) return null;
  if (stats?.designationStats?.length === 0) return null;

  const data = React.useMemo(
    () => prepareData(stats?.designationStats),
    [stats]
  );
  return (
    <ResponsiveContainer width="100%" height={400}>
      {data && (
        <BarChart
          width={600}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 30,
            bottom: 5
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={0}
            padding={{ left: 10, right: 10, top: 10, bottom: 10 }}
            // tickFormatter={formatDesignationLabelsShort}
            // tick={{
            //   fontSize: "12px",
            //   width: "30px",
            //   wordWrap: "break-word",
            //   wordBreak: "break-all",
            //   whiteSpace: "normal",
            //   height: "50px",
            //   lineHeight: "15px",
            //   texOverflow: "ellipsis",
            //   overflow: "hidden"
            // }}
            tick={<CustomizedTick />}
          />
          <YAxis allowDecimals={false} />
          <Tooltip
            cursor={{ fill: "transparent" }}
            labelFormatter={
              (label, payload) => {
                return formatDesignationLabelsLong(
                  label,
                  payload?.[0]?.payload
                );
              }
              // `${label} - ${payload?.[0]?.payload?.userCount}`
            }
          />
          <Legend wrapperStyle={{ top: 30 }} />
          <Bar
            maxBarSize={50}
            name="Billable"
            dataKey="billableCount"
            stackId="a"
            fill="#82ca9d"
          />
          <Bar
            maxBarSize={50}
            fill="#8884d8"
            name="Non Billable"
            dataKey="nonBillableCount"
            stackId="a"
          />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};
