import React, { useState, useEffect } from "react";
import { Typography, Box, OutlinedInput, Paper } from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import styles from "./ResetPassword.module.scss";
import { useHistory, useParams } from "react-router-dom";
import { AuthService } from "network/authService";

function ResetPassWord() {
  const history = useHistory();
  let { authtoken } = useParams();

  const [showPassword, setshowPassword] = useState(false);
  const [showRePassword, setshowRePassword] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [errorHandler, setErrorHandler] = useState(false);

  useEffect(() => {
    checkToken();
  }, []);

  async function checkToken() {
    let param = [authtoken];
    let checkToken = await AuthService.verifyResetToken(param);
    if (checkToken.code === 200 && checkToken.success === true) {
      // eslint-disable-next-line no-unused-vars
      let data = checkToken;
    } else {
      history.push("/login");
    }
  }

  async function handlesubmit(data) {
    let body = {
      password: data.password,
      confirmPassword: data.repassword
    };
    let param = [authtoken];
    let resetPassword = await AuthService.resetPassword(body, param);
    if (resetPassword.code === 200 && resetPassword.success === true) {
      history.push("/reset-password/success");
    }
    if (resetPassword.code === 400 && resetPassword.success === false) {
      if (resetPassword.error?.message?.length > 0) {
        let message = resetPassword.error.message[0];
        setErrorMessage(message);
        setErrorHandler(true);
      }
    }
  }

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
  const handleClickShowRePassword = () => {
    setshowRePassword(!showRePassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
    setshowPassword(!showPassword);
  };
  const handleMouseDownRePassword = (event) => {
    event.preventDefault();
    setshowRePassword(!showRePassword);
  };

  function validationSchema() {
    return Yup.object().shape({
      password: Yup.string()
        .required("required")
        .trim()
        .matches(/^(?=.*[A-Z])/, "One Uppercase required")
        .matches(/^(?=.*[!@#$%^&*])/, "One Special Case Character required")
        .matches(/^(?=.*\d)/, "One Number required")
        .min(8, "Password must be at least 8 characters")
        .max(32, "Password must not exceed 32 characters"),
      repassword: Yup.string()
        .required("required")
        .trim()
        .oneOf([Yup.ref("password")], "Password doesn't match")
    });
  }

  const initialValues = {
    password: "",
    repassword: ""
  };

  return (
    <Paper>
      <Box>
        <Typography variant="h2" component="h2" className={styles.h2_header}>
          Reset Password
        </Typography>
        <div className={styles.form_container}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handlesubmit}>
            {(formik) => {
              let checkPasswordValidation = formik.values["password"].match(
                /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d@$!%*#?&]{8,32}$/
              );
              const isFormValid =
                formik.isValid && Object.keys(formik.touched).length > 0;

              if (!isFormValid) {
                setErrorMessage(null);
              }
              return (
                <Form onSubmit={formik.handleSubmit}>
                  <Typography
                    variant="h2"
                    component="h2"
                    className={styles.inputlabel}>
                    New Password
                  </Typography>
                  <Field
                    as={OutlinedInput}
                    placeholder="Enter new password"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    value={formik.values["password"]}
                    onChange={formik.handleChange}
                    name="password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    className={styles.inputfield}
                  />
                  <Box className={styles.box_bulletpoint}>
                    <Box className={styles.bullet_point}> </Box>
                    <Box className={styles.bullet_point_text}>
                      <Typography
                        className={styles.bullet_point_text_typography}
                        variant="subtitle2"
                        sx={{
                          textDecoration:
                            checkPasswordValidation &&
                            checkPasswordValidation.length > 0
                              ? "line-through"
                              : "none"
                        }}>
                        Please include atleast 8 or max 32 characters with a mix
                        of special character, uppercase and numbers
                      </Typography>
                    </Box>
                  </Box>

                  <Typography
                    variant="h2"
                    component="h2"
                    className={styles.inputlabel}>
                    Confirm Password
                  </Typography>

                  <Field
                    as={OutlinedInput}
                    placeholder="Enter confirm password"
                    fullWidth
                    type={showRePassword ? "text" : "password"}
                    value={formik.values["repassword"]}
                    onChange={formik.handleChange}
                    name="repassword"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle repassword visibility"
                          onClick={handleClickShowRePassword}
                          onMouseDown={handleMouseDownRePassword}
                          edge="end">
                          {showRePassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    className={styles.inputfield}
                  />
                  <Box className={styles.text_error}>
                    {formik.errors.repassword === "required" ? (
                      ""
                    ) : (
                      <ErrorMessage
                        name="repassword"
                        component="div"
                        className={styles.text_danger}
                      />
                    )}
                    {/* {isFormValid && errorHandler === true ? <div className={styles.text_danger}>{errorMessage}</div> : ""} */}
                  </Box>
                  <Box className={styles.button_container}>
                    <Button
                      type="submit"
                      variant="contained"
                      className={
                        isFormValid
                          ? styles.submit_button
                          : styles.dissble_submit_button
                      }
                      disabled={isFormValid ? false : true}>
                      Reset Password
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Box>
    </Paper>
  );
}

export default ResetPassWord;
