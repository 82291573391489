import axios from "axios";
import { toast } from "react-toastify";
import store from "./redux";
import appSlice from "./redux/app/slice";

axios.interceptors.request.use(
  (config) => {
    const { dispatch } = store;
    const jwtToken = localStorage.getItem("access_token");
    if (jwtToken) {
      config.headers.token = `${jwtToken}`;
      dispatch(appSlice.actions.setLoadingData(true));
    }

    return config;
  },
  (error) => {
    const { dispatch } = store;

    dispatch(appSlice.actions.isLoggedIn(false));
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    const { dispatch } = store;
    dispatch(appSlice.actions.setMessageData({ text: "", variant: "" }));
    const { message } = response.data || {};

    dispatch(appSlice.actions.setLoadingData(false));
    dispatch(appSlice.actions.setMessageData({ text: message, variant: "success" }));
    return response;
  },
  (error) => {
    const errorMessage = error.response?.data?.message;
    const { dispatch } = store;
    dispatch(appSlice.actions.setMessageData({ text: "", variant: "" }));

    const { message } = error || {};

    if (error.response?.data?.statusCode === 401) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      dispatch(appSlice.actions.setIsLoggedInData(false));
    }

    toast.error(errorMessage, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined
    });

    dispatch(appSlice.actions.setMessageData({ text: message, variant: "error" }));
    Promise.reject(error);
  }
);
