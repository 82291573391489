import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const prepareData = (departmentStats) => {
  const data = [];
  departmentStats?.forEach((stat) => {
    // check if the both billable and non-billable count is zero
    if (
      stat.allocationDetails.billable.count == 0 &&
      stat.allocationDetails.nonBillable.count == 0
    ) {
      return;
    }
    data.push({
      name: stat.name,
      userCount: stat.resourceUserCount,
      billableCount: stat.allocationDetails.billable.count,
      nonBillableCount: stat.allocationDetails.nonBillable.count
    });
  });
  return data;
};

export const AllocationBarChartByDepartment = ({ stats }) => {
  if (Object.keys(stats).length === 0) return null;
  if (stats?.departmentStats?.length === 0) return null;

  const data = React.useMemo(() => prepareData(stats.departmentStats), [stats]);
  return (
    <ResponsiveContainer width="100%" height={400}>
      {data && (
        <BarChart
          width={600}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 30,
            bottom: 5
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={0}
            tick={{
              fontSize: "12px",
              width: "30px",
              wordWrap: "break-word",
              wordBreak: "break-all",
              whiteSpace: "normal",
              height: "50px",
              lineHeight: "15px",
              texOverflow: "ellipsis",
              overflow: "hidden"
            }}
          />
          <YAxis type="number" allowDecimals={false} />
          <Tooltip
            cursor={{ fill: "transparent" }}
            wrapperStyle={{ outline: "none" }}
            labelFormatter={(label, payload) =>
              `${label} - ${payload?.[0]?.payload?.userCount}`
            }
          />
          <Legend wrapperStyle={{ top: 30 }} />
          <Bar
            maxBarSize={50}
            name="Billable"
            dataKey="billableCount"
            stackId="a"
            fill="#82ca9d"
          />
          <Bar
            maxBarSize={50}
            name="Non Billable"
            dataKey="nonBillableCount"
            stackId="a"
            fill="#8884d8"
          />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};
