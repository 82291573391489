import React from "react";
import { Formik, Field, Form } from "formik";
import {
  Button,
  Typography,
  Divider,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";

const convertToFormikFormat = (data) => {
  return {
    uuid: data?.uuid || "",
    name: data?.name || ""
  };
};

const UpdateDepartmentModal = ({ open, onClose, department, onUpdate }) => {
  if (!department) {
    return null;
  }

  const formData = React.useMemo(
    () => convertToFormikFormat(department),
    [department]
  );
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    onClose && onClose();
  };

  const onSubmitHandler = async (formData) => {
    const data = {
      ...formData
    };
    onUpdate && onUpdate(data);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle variant="h5">Update Department</DialogTitle>
      <Divider />
      <Formik
        initialValues={formData}
        onSubmit={onSubmitHandler}
        enableReinitialize>
        {(formik) => {
          return (
            <Form>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                      Edit Department Name
                    </Typography>
                    <Field
                      as={TextField}
                      placeholder="Name"
                      fullWidth
                      valueData={formik.values.name}
                      name="name"
                      size="small"
                      multiline={true}
                      spellCheck="false"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="outlined" size="large">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" size="large">
                  Update
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export { UpdateDepartmentModal };
