// Sample service to make network call

import { API, NetworkManager } from "./core";

export class AuthService {
  static async loginByEmail(payload) {
    const instance = new NetworkManager(API.AUTH.LOGIN, payload);
    return await instance.httpRequest(false);
  }
  static async forgotPassword(payload) {
    const instance = new NetworkManager(API.AUTH.FORGOT, payload);
    return await instance.httpRequest(false);
  }
  static async resetPassword(payload, param) {
    const instance = new NetworkManager(API.AUTH.RESET, payload, param);
    return await instance.httpRequest(false);
  }
  static async verifyResetToken(param) {
    const instance = new NetworkManager(API.AUTH.RESETVERIFY, {}, param);
    return await instance.httpRequest(false);
  }
  static async updateUser(payload) {
    const instance = new NetworkManager(API.AUTH.UPDATEUSER,payload);
    return await instance.httpRequest(true);
  }
  static async updatePassword(payload) {
    const instance = new NetworkManager(API.AUTH.UPDATEPASSWORD,payload);
    return await instance.httpRequest(true);
  }
}
