// List all endpoints here
// @ts-check
import { Endpoint } from "./apiModel";
import { HTTP_METHODS } from "./httpMethods";

// ******************
// Endpoint class takes 3 params in constructor ==> "endpoint", "http-method", "API-version"
// By default, version is set to v1
// ******************
export const API = {
  AUTH: {
    LOGIN: new Endpoint("/auth/login", HTTP_METHODS.POST),
    FORGOT: new Endpoint("/auth/forgotPassword", HTTP_METHODS.POST),
    RESET: new Endpoint("/auth/resetPassword", HTTP_METHODS.PATCH),
    RESETVERIFY: new Endpoint("/auth/resetPassword", HTTP_METHODS.GET),
    UPDATEUSER: new Endpoint("/auth/myProfile", HTTP_METHODS.PATCH),
    UPDATEPASSWORD: new Endpoint("/auth/updatePassword", HTTP_METHODS.PATCH)
  },
  RESOURCE: {
    ADD: new Endpoint("/resource/addResource", HTTP_METHODS.POST),
    UPDATE: new Endpoint(
      "/resource/resourceId",
      HTTP_METHODS.PATCH
    ),
    DELETE: new Endpoint("/resource/resourceId", HTTP_METHODS.DEL),
    LIST: new Endpoint("/resource", HTTP_METHODS.GET)
  },
  PROJECT: {
    ADD: new Endpoint("/project/addproject", HTTP_METHODS.POST),
    UPDATE: new Endpoint("/project/projectId", HTTP_METHODS.PATCH),
    DELETE: new Endpoint("/project/projectId", HTTP_METHODS.DEL),
    LIST: new Endpoint("/project", HTTP_METHODS.GET)
  },
  DEPARTMENT: {
    ADD: new Endpoint("/department/addDepartment", HTTP_METHODS.POST),
    UPDATE: new Endpoint(
      "/department/departmentId",
      HTTP_METHODS.PATCH
    ),
    DELETE: new Endpoint(
      "/department/departmentId",
      HTTP_METHODS.DEL
    ),
    LIST: new Endpoint("/department", HTTP_METHODS.GET)
  },
  DESIGNATION: {
    ADD: new Endpoint("/designation/addDesignation", HTTP_METHODS.POST),
    UPDATE: new Endpoint(
      "/designation/designationId",
      HTTP_METHODS.PATCH
    ),
    DELETE: new Endpoint(
      "/designation/designationId",
      HTTP_METHODS.DEL
    ),
    LIST: new Endpoint("/designation", HTTP_METHODS.GET)
  },
  ALLOCATION: {
    ADD: new Endpoint("/resourceAllocation/add", HTTP_METHODS.POST),
    LIST: new Endpoint("/resourceAllocation/allDetails", HTTP_METHODS.GET)
  },
  ORDERS: {
    LIST: new Endpoint("/orders_list", HTTP_METHODS.GET),
    CREATE: new Endpoint("new_order", HTTP_METHODS.POST),
    UPDATE: new Endpoint("order_new", HTTP_METHODS.PUT)
  },
  DASHBOARD: {
    COUNT: new Endpoint("/dashboard/", HTTP_METHODS.GET)
  }
};
